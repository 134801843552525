import { useState } from 'react';
import { Button, Rating, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { getUser } from '../../../redux/slices/AuthUser';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../axios/axiosInstance';
import { useTranslation } from 'react-i18next';
import { useShowAlert } from '../../../hooks/useShowAlert';
export const AddRating = () => {
    const { showAlert } = useShowAlert();
    const { t } = useTranslation("rentProfile");
    const { userId } = useParams()
    const authUser = useSelector(getUser); // get user id from redux
    let [rating, setRating] = useState(2);
    const [loading, setLoading] = useState(false);

    const handleSaveRating = () => {
        setLoading(true);
        axiosInstance
            .post(
                '/ratings',
                {
                    itemOwnerID: userId,
                    rating: rating,
                    userID: authUser?.id,
                }
            )
            .then(() => {
                showAlert("success", "Rating Added Successfully!");

            })
            .catch((err) => {
                showAlert("error", err.response.data.error);
                // reset rating
                setRating(2);

            }).finally(() => {
                setLoading(false);
            });
    };

    // handle rating change
    const handleRatingUser = (event) => {
        setRating(parseInt(event.target.value));

    };

    return (
        <Stack spacing={2} sx={{ my: 2 }}>
            <Rating
                name="simple-controlled"
                value={rating}
                onChange={handleRatingUser}
                size="large"
                sx={{ color: 'primary.main' }}

            />
            <Button variant="contained" sx={{ mt: 2, width: '200px' }} onClick={handleSaveRating} disabled={loading}>
                {t("addRating")}
            </Button>
        </Stack>
    );
};
