import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
export const CustomTextField = styled(TextField)({
    color: "secondary.main",
    '& .MuiOutlinedInput-root': { // To remove outline
        borderRadius: "10px",
        backgroundColor: "white",
        color: "secondary.main",
        '& fieldset': {
            borderColor: 'transparent',
        },
        '&:hover fieldset': {
            borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent',
        },
        '& .MuiInputBase-input::placeholder': {
            color: 'black',
            fontSize: '12px',
        },
    },
    '& .MuiFormLabel-root': { // To change label color
        color: 'black', // Replace 'desiredColor' with the color you want
    },
});