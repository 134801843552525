import { Box, Button, FormLabel, Stack } from "@mui/material";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useTranslation } from "react-i18next";
export const RenderAdditionalImages = ({ push, remove, values, handleImageChange, setFieldValue, imageLoading }) => {
  const { t } = useTranslation("add-product");
  return (
    <Stack direction="row" sx={{ mt: 2, flexWrap: "wrap" }} spacing={1}>
      {values.pictures.slice(1).map((image, index) => (
        <Stack key={index} spacing={2} sx={{ mx: "3px" }}>
          <Box>
            <input
              type="file"
              id={`pictures.${index + 1}`}
              onChange={(event) => handleImageChange(event, index + 1, setFieldValue)}
              style={{
                width: "0px",
                height: "0px",
                overflow: "hidden",
              }}
            />
            <FormLabel htmlFor={`pictures.${index + 1}`}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px dashed #ccc",
                  borderRadius: 4,
                  cursor: "pointer",
                  width: "120px",
                  height: "100px",
                  overflow: "hidden",
                }}
              >
                {imageLoading[index + 1] ? 'Loading...' : image ? <img src={image} alt='image' style={{ width: "100%", height: "100%", objectFit: "cover" }} /> : t("upload")}
              </Box>
            </FormLabel>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="outlined"
              sx={{ color: "primary.main", width: "85px" }}
              onClick={() => remove(index + 1)}
            >
              {t("Remove")}
            </Button>
          </Box>
        </Stack>
      ))}
      {values.pictures.length < 6 && (
        <Stack spacing={2} pt={3}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px dashed #ccc",
              borderRadius: 4,
              cursor: "pointer",
              width: "85px",
              height: "100px",
            }}
            onClick={() => push(null)}
          >
            <AddPhotoAlternateIcon />
          </Box>
        </Stack>
      )}
    </Stack>
  )
};