import { Box, Container, Grid, Stack, Button, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import axiosInstance from "../axios/axiosInstance";
import { useParams } from "react-router-dom"
import { UserAvatar } from "../components/general/UserAvatar";
import { ControleBlockUser } from "../components/rent-profile/ControleBlockUser";
import RatingContainer from "../components/rent-profile/ratingManige/RatingContainer";
import OpenListing from "../components/rent-profile/OpenListing";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
export const RentProfile = () => {
  const { t } = useTranslation("rentProfile")
  const navigate = useNavigate()
  const [user, setUser] = useState(null)
  const { userId } = useParams()
  // handle navigation to contact page
  const handleNavigationToContact = (user) => {
    // save current route to local storage to use it back from contact page
    localStorage.setItem("prevRoutContact", window.location.pathname);
    // navigate to contact page
    navigate(`/contactWithUser/${user?._id}/${user?.username || "user"}`)

  }
  // fetch user data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`user/${userId}`);
        setUser(response.data.user);
      } catch (_) {/* empty */ }
    };
    fetchData();
  }, [userId])
  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="lg">
        <Grid container spacing={8}>
          <Grid item xs={12} md={4}>
            {/** User Avatar */}
            <UserAvatar user={user} />
            {/** controle */}
            <Stack spacing={2} pt={3}>
              <Button variant="contained" color="shadow" sx={{ color: "primary.main" }} onClick={() => handleNavigationToContact(user)}>
                {t("contact")}
              </Button>
              <ControleBlockUser />
            </Stack>
            {/** Rating */}
            <RatingContainer />
          </Grid>
          <Grid item xs={12} md={8}>
            {/** Open Listing */}
            <Typography variant="h6" component="h4" sx={{ color: "primary.main", fontWeight: "bold", mb: 4 }}>
              {t("openListing")}
            </Typography>
            <OpenListing />
          </Grid>
        </Grid>

      </Container>
    </Box>
  )
}
