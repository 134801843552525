import { Box, Grid, Stack, Typography } from '@mui/material';
import uploadImage from '../../../assets/images/uploadImg.webp';
import { useTranslation } from 'react-i18next';
export const ProfileImage = ({ setFieldValue, values }) => {
  const { t } = useTranslation("UserDetailsDialog");
  return (
    <Grid item xs={12}>
      <Typography variant="body1" gutterBottom component="div">
       {t("Picture")}
      </Typography>
      <Box sx={{ border: "1px solid #80808066", p: 1, borderRadius: "10px" }}>
        <Stack spacing={2} direction="row">
          <Box position="relative" >
            <input
              id="upload-file"
              type="file"
              onChange={(event) => {
                setFieldValue("profilePicture", event.currentTarget.files[0]);
              }}
              name="profilePicture"
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                opacity: 0
              }}
            />
            <Stack direction="row" spacing={1} alignItems="center">
              <img src={uploadImage} alt="upload" loading='lazy' style={{ width: "30px", height: "30px" }} />
              <Typography variant="body1" gutterBottom component="div" sx={{ fontSize: ["8px", "16px"] }}>
                {values.profilePicture?.name || t("drag")}
              </Typography>
            </Stack>
          </Box>

          <Typography variant="body1" gutterBottom component="div" sx={{ color: "secondary.main", fontWeight: "bold" }}>
            {t("or")}
          </Typography>

          <label htmlFor="upload-file" style={{
            backgroundColor: "#FE1F02",
            color: "white",
            borderRadius: "5px",
            padding: "5px",
            cursor: "pointer",
            fontSize: "12px",
            textAlign: "center",
            width: "100px"
          }}>

            {t("select-file")}
          </label>

        </Stack>
      </Box>

    </Grid>
  )
}
