import { Skeleton, Stack } from "@mui/material"


export const EditAcountInfoSkeleton = () => {
    return (
        <Stack spacing={4} >
            <Skeleton variant="rectangular" sx={{ width: "100%", height: "30px" }} />
            <Skeleton variant="rectangular" sx={{ width: "100%", height: "30px" }} />
            <Skeleton variant="rectangular" sx={{ width: "100%", height: "30px" }} />
            <Skeleton variant="rectangular" sx={{ width: "100%", height: "30px" }} />
            <Skeleton variant="rectangular" sx={{ width: "100%", height: "30px" }} />
            <Skeleton variant="rectangular" sx={{ width: "100%", height: "30px" }} />
        </Stack>

    )
}
