import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
export const CustomDatePicker = styled(DatePicker)({
    backgroundColor: "white",
    color: "secondary.main",
    borderRadius: "20px",
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
        border: 'none',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
        border: 'none',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
        border: 'none',
      },
    },
  
    '& .MuiFormLabel-root': {
      color: 'black',
    },
  });