import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { userDetailsValidationSchema } from "../../../validation/validationSchemas";
import { useSelector } from "react-redux";
import { InputFiled } from "./InputFiled";
import { ProfileImage } from "./ProfileImage";
import { getUser } from "../../../redux/slices/AuthUser";
import { CustomDialog } from "./helper/CustomDialog";
import useSubmitData from "./hook/useSumbitData";
import { useTranslation } from "react-i18next";
import { SelectLocation } from "../SelectLocation";
const UserDetailsDialog = () => {
  const { t } = useTranslation("UserDetailsDialog");
  const { loading, handleSubmit, handleClose } = useSubmitData();
  const authUser = useSelector(getUser); // get user id from redux
  const openDialog = useSelector((state) => state.openDialog.open); // get open dialog

  const initialValues = {
    firstName: authUser?.user ? authUser.user.firstName : "",
    lastName: authUser?.user ? authUser.user.lastName : "",
    displayName: authUser?.user ? authUser.user.displayName : "",
    phoneNumber: authUser?.user ? authUser.user.phoneNumber : "",
    location: authUser?.user ? authUser.user.location : { lat: 0, lng: 0 },
    profilePicture: "",
  };

  return (
    <CustomDialog open={openDialog} onClose={handleClose} scroll="paper">
      <DialogTitle id="scroll-dialog-title" sx={{ color: "primary.main", fontSize: ["16px", "25px"] }}>
        {t("title")}
      </DialogTitle>
      <DialogContent
        sx={{
          "&::-webkit-scrollbar": {
            width: "13px",
            boxShadow: "inset 0 0 5px grey",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "rgba(128, 128, 128, 0.375)",
            borderRadius: "10px",
          }
        }}
      >
        <DialogContentText
          sx={{ mt: 2 }}
          id="scroll-dialog-description"
          tabIndex={-1}
          component={"div"}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={userDetailsValidationSchema}
            enableReinitialize={true}
          >
            {({ values, errors, touched, setFieldValue }) => (
              <Form>
                <Grid container spacing={2}>
                  <InputFiled errors={errors} touched={touched} />
                  <Grid item xs={12}>
                    <SelectLocation
                      location={values.location}
                      onLocationChange={(location) => setFieldValue("location", location)}
                      mapId="e8b1b7b3b3b4b3b3hgctt"
                    />
                    {errors?.location && (
                      <Typography variant="caption" color="error">
                        {t(`validation.${errors?.location}`)}
                      </Typography>
                    )
                    }
                  </Grid>
                  <ProfileImage values={values} setFieldValue={setFieldValue} />
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      {loading ? t("loading") : t("submit")}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          {t("skip")}
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};

export default UserDetailsDialog;
