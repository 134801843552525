import { Box, } from "@mui/material"
import { PopularProducts } from "../components/Home/popular-products/ListProducts"
import { Earning } from "../components/Home/Earning/Earning"
import { StartRent } from "../components/Home/StartRent/StartRent"
import { HowItWork } from "../components/Home/HowItWork/HowItWork"
import { YourBack } from "../components/Home/YourBack/YourBack"
import { DounloadApp } from "../components/Home/DounloadApp/DounloadApp"
/* import { Testimonials } from "../components/Home/Testimonials/Testimonials" */
import { HeroSection } from "../components/Home/heroSection/heroSection"
import UserDetailsDialog from "../components/general/user-details-dialog/UserDetailsDialog"
import { CategoriesList } from "../components/Home/categories/CategoriesList"
import { Referrals } from "../components/Home/Referrals/Referrals"


export  const Home = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <UserDetailsDialog />
       <HeroSection/> 
      <CategoriesList/>
      <PopularProducts />
      <Earning />
      <StartRent />
      <HowItWork />
      <Referrals/>
      <YourBack />
      <DounloadApp />
     {/*  <Testimonials /> */}

    </Box>

  )
}
