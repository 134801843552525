import { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import axiosInstance from "../../axios/axiosInstance";
import { getUser } from "../../redux/slices/AuthUser";
import { NotificationContext } from "../../context";
import { Badge, Drawer, Box, Typography, Tooltip, Tabs, Tab } from "@mui/material";
// MUI Components and Icons
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import PendingIcon from "@mui/icons-material/Pending";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import CommentIcon from '@mui/icons-material/Comment';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
// Custom Components
import { Unread } from "./unread-messages/Unread";
import { Approved } from "./approved/Approved";
import { Pendding } from "./pendding/Pendding";
import { Rejected } from "./rejected/Rejected";
import { Ongoing } from "./Ongoing/Ongoing";
import { WatchList } from "./watch-list/WatchList";
import { Comments } from "./comments/Comments";
import { useTranslation } from "react-i18next";
const BoxStyle = {
  maxWidth: ["300px", "350px"],
  minWidth: "300px",
  height: "auto",
  p: 2,
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '10px',
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: '50px',
    backgroundColor: 'rgb(0 0 0 / 0%)',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '50px',
    backgroundColor: 'secondary.main',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: 'secondary.main',
  },
};
const drawerStyle = {
  "& .MuiPaper-root": {
    borderRadius: "0px 50px 50px 0px",
    backgroundColor: "text.main",
    "& .MuiBox-root": {
      backgroundColor: "text.main",
    },
  },
};
export const Notifications  = () => {
  const { t } = useTranslation("notifications");
  const AuthUser = useSelector(getUser);
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [totalCountNotification, setTotalCountNotification] = useState(0);
  const [allNotificationsCount, setAllNotificationsCount] = useState([]);
  const [updateNotification, setUpdateNotification] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleUpdateNotifications = useCallback(() => {
    setUpdateNotification((prev) => !prev);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const getNotificationsCount = useCallback(() => {
    axiosInstance.get("/notifications/unread-count").then((res) => {
      setTotalCountNotification(res.data.totalUnreadCount);
      setAllNotificationsCount(res.data.unreadCounts);
    });
  }, []);

  useEffect(() => {
    getNotificationsCount();
    axiosInstance.get(`/user/check-watchlist-availability/${AuthUser?.id}`);
  }, [AuthUser?.id, updateNotification, getNotificationsCount]);

  useEffect(() => {
    handleCloseDrawer();
  }, [navigate, location.pathname]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getNotificationsCount();
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, [getNotificationsCount]);

  const renderTabBadge = (type, Icon, tooltip) => (
    <Badge
      badgeContent={allNotificationsCount.find((item) => item.type === type)?.count}
      onClick={handleOpenDrawer}
      sx={{ cursor: "pointer", color: "primary.main" }}
    >
      <Tooltip title={t(tooltip)} placement="top">
        <Icon sx={{ color: "text.secondary" }} />
      </Tooltip>
    </Badge>
  );

  return (
    <NotificationContext.Provider value={{updateNotification, handleUpdateNotifications,handleCloseDrawer }}>
      <Badge
        badgeContent={totalCountNotification}
        onClick={handleOpenDrawer}
        sx={{ cursor: "pointer", color: "primary.main" }}
      >
        <CircleNotificationsIcon sx={{ fontSize: "35px" }} />
      </Badge>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={handleCloseDrawer}
        sx={drawerStyle}
      >
        <Box
          sx={BoxStyle}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{ display: "flex", alignItems: "center" }}
          >
          {t("title")}{" "}
            <CircleNotificationsIcon sx={{ ml: 2, color: "primary.main" }} />
          </Typography>

          <Box sx={{ py: 3 }}>
            <Tabs value={value} onChange={handleChange} sx={{ pb: 3 }}>
              <Tab sx={{ minWidth: "40px", maxWidth: "40px" }} label={renderTabBadge("message", MarkAsUnreadIcon, "unreadMessages")} />
              <Tab sx={{ minWidth: "40px", maxWidth: "40px" }} label={renderTabBadge("transaction rejected", CancelPresentationIcon, "rejectedRequests")} />
              <Tab sx={{ minWidth: "40px", maxWidth: "40px" }} label={renderTabBadge("transaction approved", CheckBoxIcon, "acceptedRequests")} />
              <Tab sx={{ minWidth: "40px", maxWidth: "40px" }} label={renderTabBadge("transaction created", PendingIcon, "pendingRequests")} />
              <Tab sx={{ minWidth: "40px", maxWidth: "40px" }} label={renderTabBadge("transaction paid", ConnectWithoutContactIcon, "ongoingOrders")} />
              <Tab sx={{ minWidth: "40px", maxWidth: "40px" }} label={renderTabBadge("comment", CommentIcon, "comments")} />
              <Tab sx={{ minWidth: "40px", maxWidth: "40px" }} label={renderTabBadge("product available", AlarmOnIcon, "watchlist")} />
            </Tabs>
            {value === 0 && <Unread />}
            {value === 1 && <Rejected />}
            {value === 2 && <Approved />}
            {value === 3 && <Pendding />}
            {value === 4 && <Ongoing />}
            {value === 5 && <Comments />}
            {value === 6 && <WatchList />}
          </Box>
        </Box>
      </Drawer>
    </NotificationContext.Provider>
  );
};


