import { Avatar, IconButton, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
export const UserAvatar = ({ user, handleOpenStack }) => {
    const { t } = useTranslation("header");
    return (
        <Tooltip title={t("Account settings")} >
            <IconButton
                onClick={handleOpenStack}
                size="small"
            >
                <Avatar
                    sx={{ textDecoration: "none", }}
                >
                    {user && user.profilePicture ? (
                        <img
                            src={user.profilePicture}
                            alt={user.username || "user"}
                            style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                        />
                    ) : (
                        user ? user.username ? user.username[0].toUpperCase() : null : null
                    )}
                </Avatar>
            </IconButton>
        </Tooltip>
    )
}
