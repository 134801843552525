import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import axiosInstance from '../../axios/axiosInstance';
import DynamicField from './DynamicField';
import { useTranslation } from 'react-i18next';
const SectionWrapper = ({ children }) => (
  <Box sx={{ backgroundColor: "rgb(247,247,247)", p: 3, borderRadius: "10px" }}>
    {children}
  </Box>
);

const InputField = ({ setFieldValue, errors, touched, values }) => {
  const { t } = useTranslation("add-product");
  const [categories, setCategories] = useState([]);
  const timeOptions = ["Day", "Week", "Month", "Year"].map(time => ({ value: time, label: time }));

  useEffect(() => {
    axiosInstance.get("/products/categories")
      .then(res => setCategories(res.data.map(cat => ({ value: cat._id, label: cat.name }))))
      .catch(() => setCategories([]));
  }, []);

  const fieldConfigs = [
    { name: 'category', label: 'Category', type: 'select', options: categories },
    { name: 'name', label: 'Product Name', type: 'text' },
    { name: 'price', label: 'Price', type: 'text' },
    { name: 'per', label: 'Per', type: 'select', options: timeOptions },
    { name: 'description', label: 'Description', type: 'text', multiline: true, rows: 4 },
    { name: 'condition', label: 'Condition', type: 'radio', options: [{ value: 'New', label: 'New Items' }, { value: 'Used', label: 'Used Item' }] },
  ];

  return (
    <Stack spacing={3}>
      {fieldConfigs.map(fieldConfig => (
        <SectionWrapper key={fieldConfig.name}>
          <Typography variant="h6" sx={{ my: 2, color: "primary.main", fontFamily: "Poppins-Bold" }}>
            {t(fieldConfig.label)}
          </Typography>
          <DynamicField
            fieldConfig={fieldConfig}
            setFieldValue={setFieldValue}
            values={values}
            errors={errors}
            touched={touched}
          />
        </SectionWrapper>
      ))}
    </Stack>
  );
};

export default InputField;
