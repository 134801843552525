import { useNavigate } from "react-router-dom";

const useNavigateBasedOnPath = () => {
  const navigate = useNavigate();

  const navigateBasedOnPath = () => {
    const prevPath = JSON.parse(localStorage.getItem("pathHistory"));

    if (
      prevPath?.[0] === "/verification/email" ||
      prevPath?.[0] === "/verification/phone" ||
      prevPath?.[0] === "/login" ||
      prevPath?.[0] === "/signup" ||
      prevPath?.length === 0
    ) {
      navigate("/");
    } else {
      navigate(prevPath?.[0]);
    }
  };

  return navigateBasedOnPath;
};

export default useNavigateBasedOnPath;
