import { Container, Typography, Box } from '@mui/material';


export const GuaranteePolicy = () => {
    return (
        <Box>
            <Typography
                variant="h4"
                align="center"
                sx={{ color: 'primary.main', fontFamily: 'Poppins-Bold', fontSize: ["18px", "35px"], my: 8 }}
            >
                Guarantee Policy
            </Typography>
            <Box sx={{ backgroundColor: "text.main" }}>
                <Container maxWidth="xl" sx={{ py: 8 }}>

                    <Box>
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2, fontFamily: "Poppins-Bold" }}>
                            Direct Rent Privacy Policy
                        </Typography>
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2 }}>
                            We take pride in our community. You’ve also trusted us with a big responsibility, so we’re committed to
                            upholding a high standard when it comes to our privacy practices. It’s important to us that you’re comfortable
                            on The Direct Rent, so we provide options for you to control how much information you share and your
                            communication preferences. We use secure technologies and follow best practices.
                        </Typography>
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2 }}>
                            By visiting The Direct Rent website, you are accepting the terms of this Privacy Policy. Any external links to
                            other websites are clearly identifiable as such, and we are not responsible for the content or the privacy
                            policies of these other websites.
                            <br />
                            If you have any questions about this policy please contact us through the site, or directly at
                            suport@Directrent.co.nz.
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2, fontFamily: "Poppins-Bold" }}>
                            The Legal Version From Our Lawyers:
                        </Typography>
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2 }}>
                            Registrar
                            <br />
                            The Direct Rent provided by GearBear Ltd
                            <br />
                            www.Directrent.io
                            <br />
                            www.Directrent.co.nz
                            <br />
                            www.Directrent.nz
                            <br />
                            www.larent.co.nz
                            <br />
                            www.larent.nz
                            <br />
                            hello@Directrent.co.nz
                            <br />
                            Use of personal details (purpose of register)
                            <br />
                            Personal details are collected to make communication and use of service possible. Details can be used for
                            <br />
                            communication between service providers and users and also for direct communication between users.
                            <br />
                            Some personal details are visible on the profile page of the user, but those details are voluntary (except
                            name).
                        </Typography>
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2 }}>
                            The handling of personal details is not outsourced, but the register data is stored on a server that is rented
                            from a third party company.
                            <br />
                            Information content of the register
                            <br />
                            The following information may be stored in the register:
                            <br />
                            •	Personal details: Name, email address, phone number, street address
                            <br />
                            •	Account details: username, password (stored in encrypted format)
                            <br />
                            •	The description text that the user may write about him/herself
                            <br />
                            •	The offers and requests the user has posted to the service
                            <br />
                            •	The given and received feedback and badges
                            <br />
                            •	Statistical data about service usage, e.g. number times the user has logged in
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2, fontFamily: "Poppins-Bold" }}>

                            Regular sources of information
                        </Typography>
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2 }}>
                            Personal details are given by the user on registration to the service or when using it later.
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2, fontFamily: "Poppins-Bold" }}>
                            Aggregated Statistics
                        </Typography>
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2 }}>
                            The Direct Rent may collect statistics about the behavior of visitors to its websites. he Direct Rent may
                            display this information publicly or provide it to others. However, The Direct Rent does not disclose personally-
                            identifying information other than as described below.
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2, fontFamily: "Poppins-Bold" }}>
                            Protection of Certain Personally-Identifying Information
                        </Typography>
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2 }}>
                            The Direct Rent discloses potentially personally-identifying and personally-identifying information only to
                            those of its employees, contractors and affiliated organizations that (i) need to know that information in order
                            to process it on The Direct Rent’s behalf or to provide services available at The Direct Rent’s websites, and (ii)
                            that have agreed not to disclose it to others. Some of those employees, contractors and affiliated
                            organizations may be located outside of your home country; by using the Direct Rent’s websites, you consent
                            to the transfer of such information to them. The Direct Rent will not rent or sell potentially personally-
                            identifying and personally-identifying information to anyone. Other than to its employees, contractors and
                            affiliated organizations, as described above, The Direct Rent discloses potentially personally-identifying and
                            personally-identifying information only in response to a subpoena, court order or other governmental request,
                            or when The Direct Rent believes in good faith that disclosure is reasonably necessary to protect the property
                            or rights of The Direct Rent, third parties or the public at large. If you are a registered user of The Direct Rent
                            website and have supplied your email address, The Direct Rent may occasionally send you an email to tell
                            you about new features, solicit your feedback, or just keep you up to date with what’s going on with The Direct
                            Rent and our products. e expect to keep this type of email to a minimum. If you send us a request (for
                            example via a support email or via one of our feedback mechanisms), we reserve the right to publish it in
                            order to help us clarify or respond to your request or to help us support other users. The Direct Rent takes all
                            measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of
                            potentially personally-identifying and personally-identifying information.
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2, fontFamily: "Poppins-Bold" }}>

                            Storage of Personal Information
                        </Typography>
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2 }}>
                            Direct Rent is a New Zealand company with its head-office located in Auckland, New Zealand. For the
                            purposes of the General Data Protection Regulation (GDPR), New Zealand is considered a country which
                            provides adequate protections for Personal Information, as confirmed by the European Commission in
                            Commission Decision 2013/65/EU.
                            Direct Rent uses third-party service providers Market Place service: Sharetribe Ltd
                            www.sharetribe.com info (at) sharetribe.com .
                            Some of these providers may not be located in New Zealand.
                        </Typography>
                        <br />
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2, }}>

                            We will only retain your Personal Information for as long as necessary to fulfil the purposes we collected it for,
                            including the purposes of satisfying any legal, accounting or statutory reporting requirements.
                            In order to determine the appropriate retention period for Personal Information, we consider the following
                            factors; the amount, nature and sensitivity of the data, potential risk of harm from unauthorised use or
                            disclosure, the purposes for which we process your Personal Information and whether we can achieve those
                            purposes through other means.
                            We may retain certain information subsequent to the termination of your account, for example, if it is
                            necessary to fulfil our legal obligations or to exercise, to maintain security, to prevent fraud and abuse and to
                            defend or enforce our rights.
                            Direct Rent has appointed a Privacy Officer who can be contacted suport@Directrent.co.nz.
                        </Typography>
                    </Box>
                    <Box >
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2, fontFamily: "Poppins-Bold" }}>
                            Your Rights
                        </Typography>
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2 }}>
                            You can access, amend and delete the Personal Information you have provided to us by emailing us at
                            suport@Directrent.co.nz
                            If you decide to terminate your account, we will deactivate it, and remove your Personal Information.
                            Remember that once your account is closed, you will no longer be able to log in and access your Personal
                            Information. You can, however, open a new account at any time.
                            By emailing our Privacy Officer, you have the following rights with regard to your Personal Information:
                            Right to request access to your Personal Information. This enables you to receive a copy of the Personal
                            Information we hold about you and to check that we are lawfully processing it.
                            The right to request to correct your Personal Information if it is inaccurate. You may also supplement any
                            incomplete Personal Information we have, taking into account the purposes of the processing.
                        </Typography>
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2 }}>
                            The right to request deletion of your Personal Information if;
                            <br />
                            •	Your Personal Information is no longer necessary for the purposes for which we collected or processed; or
                            <br />
                            •	You withdraw your consent if the processing of your Personal Information is based on consent and no other
                            legal ground exists; or
                            <br />
                            •	you object to the processing of your Personal Information and we do not have an overriding legitimate
                            ground for processing; or
                            <br />
                            •	Your Personal Information is unlawfully processed; or
                            <br />
                            •	Your Personal Information has to be deleted for compliance with a legal obligation.
                            <br />
                            •	The right to object to the processing of your Personal Information. We will comply with your request, unless
                            we have a compelling overriding legitimate interest for processing or we need to continue processing your
                            Personal Information to establish, exercise or defend a legal claim.
                            <br />
                            •	The right to restrict the processing of Personal Information, if:
                            <br />
                            •	The accuracy of your Personal Information is contested by you, for the period in which we have to verify the
                            accuracy of the Personal Information; or
                            <br />
                            •	The processing is unlawful and you oppose the deletion of your Personal Information and request restriction;
                            or
                            <br />
                            •	We no longer need your Personal Information for the purposes of processing, but your Personal Information
                            is required by you for legal claims; or
                            <br />
                            •	You have objected to the processing, for the period in which we have to verify overriding legitimate grounds
                            To exercise your rights you can file a request by email to suport@Directrent.co.nz.
                            We may need to request specific information from you to help us confirm your identity and ensure your right
                            to access your Personal Information (or to exercise any of your other rights). This is a security measure to
                            ensure that Personal Information is not disclosed to any person who has no right to receive it. In an effort to
                            speed up our response, we may also contact you to ask you for further information in relation to your request.
                        </Typography>
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2 }}>
                            Cookies
                            <br />
                            A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s browser
                            provides to the website each time the visitor returns. The Direct Rent uses cookies to help The Direct Rent
                            identify and track visitors, their usage of The Direct Rent website, and their website access preferences. The
                            Direct Rent visitors who do not wish to have cookies placed on their computers should set their browsers to
                            refuse cookies before using The Direct Rent’s websites, with the drawback that certain features of The Direct
                            Rent’s websites may not function properly without the aid of cookies.
                            Business Transfers
                            If The Direct Rent, or substantially all of its assets, were acquired, or in the unlikely event that The Direct Rent
                            goes out of business or enters bankruptcy, user information would be one of the assets that is transferred or
                            acquired by a third party. You acknowledge that such transfers may occur, and that any acquirer of The Direct
                            Rent may continue to use your personal information as set forth in this policy.
                            Ads
                            Ads appearing on any of our websites may be delivered to users by advertising partners, who may set
                            cookies. These cookies allow the ad server to recognize your computer each time they send you an online
                            advertisement to compile information about you or others who use your computer. This information allows ad
                            networks to, among other things, deliver targeted advertisements that they believe will be of most interest to
                            you. This Privacy Policy covers the use of cookies by The Direct Rent and does not cover the use of cookies by
                            any advertisers.
                            Privacy Policy Changes
                            Although most changes are likely to be minor, The Direct Rent may change its Privacy Policy from time to time,
                            and in The Direct Rent’s sole discretion. The Direct Rent encourages visitors to frequently check this page for
                            any changes to its Privacy Policy. our continued use of this site after any change in this Privacy Policy will
                            constitute your acceptance of such change.
                        </Typography>
                    </Box>

                </Container>
            </Box>
        </Box>
    );
};
