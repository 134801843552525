import { Form } from 'formik';
import { Button, CircularProgress } from '@mui/material';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { useTogglePassword } from '../helper/passwordUtils';
import { InputField } from './HandleInput';
import { useTranslation } from 'react-i18next';
export const FieldInput = ({ values, handleChange, handleBlur, touched, errors, loading }) => {
    const { t } = useTranslation("referralSignup");
    const { showPassword, getPasswordInputAdornment } = useTogglePassword();
    return (
        <Form>
            <InputField
                id="username"
                name="username"
                placeholder="username"
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.username}
                error={errors.username}
                startIcon={<PermContactCalendarIcon sx={{ fontSize: "15px" }} />}
            />

            <InputField
                id="email"
                name="email"
                type="email"
                placeholder="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.email}
                error={errors.email}
                startIcon={<MailOutlineIcon sx={{ fontSize: "15px" }} />}
            />

            <InputField
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.password}
                error={errors.password}
                startIcon={<LockOpenIcon sx={{ fontSize: "15px" }} />}
                endIcon={getPasswordInputAdornment()}
            />

            <InputField
                id="repeatPassword"
                name="repeatPassword"
                type={showPassword ? "text" : "password"}
                placeholder="confirmPassword"
                value={values.repeatPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.repeatPassword}
                error={errors.repeatPassword}
                startIcon={<LockOpenIcon sx={{ fontSize: "15px" }} />}
                endIcon={getPasswordInputAdornment()}
            />

            <InputField
                id="referral"
                name="referral"
                placeholder="referralCode"
                value={values.referral}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.referral}
                error={errors.referral}
                startIcon={<Diversity3Icon sx={{ fontSize: "15px" }} />}
            />

            <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ borderRadius: "10px", py: 1, mt: 2 }}
            >
                {t("form.signUp")}
                {loading && (
                    <CircularProgress
                        size={20}
                        sx={{ color: "#dcc4c4", marginLeft: "5px" }}
                    />
                )}
            </Button>
        </Form>
    );
};
