import { Box, Button, Container, FormControl, Paper, Stack, Typography } from "@mui/material";
import { Form, Formik, Field } from "formik";
import { ResetPasswordValidationSchema } from "../validation/validationSchemas";
import { CustomTextField } from "../components/auth/CustomTextField";
import { useResetPassword } from "../components/auth/hooks/useResetPassword";
import { useTogglePassword } from "../components/auth/helper/passwordUtils";
import { useTranslation } from "react-i18next";
export const ResetPassword = () => {
  const { t } = useTranslation("resetPassword");
  const { handleSubmit, loading } = useResetPassword();
  const { showPassword, getPasswordInputAdornment } = useTogglePassword();

  const initialValues = {
    resetToken: "",
    newPassword: "",
  };

  return (
    <Box sx={{ py: 8 }}>
      <Typography
        variant="h4"
        align="center"
        sx={{ mt: 2, color: "primary.main", fontWeight: "bold" }}
      >
        {t("title")}
      </Typography>
      <Container maxWidth="sm" sx={{ height: "50vh", mt: 4 }}>
        <Paper elevation={3} sx={{ p: 4, backgroundColor: "secondary.main", borderRadius: "10px" }}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={ResetPasswordValidationSchema}
          >
            {({ errors, touched }) => (
              <Form>
                <Stack spacing={2} sx={{ py: 2 }}>
                  <Field
                    as={CustomTextField}
                    fullWidth
                    id="resetToken"
                    name="resetToken"
                    placeholder={t("enterCode")}
                    variant="outlined"
                    error={touched.resetToken && Boolean(errors.resetToken)}
                    helperText={touched.resetToken && errors.resetToken}
                  />
                  <FormControl fullWidth>
                    <Field
                      as={CustomTextField}
                      fullWidth
                      id="newPassword"
                      name="newPassword"
                      placeholder={t("enterNewPassword")}
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      error={touched.newPassword && Boolean(errors.newPassword)}
                      helperText={touched.newPassword && errors.newPassword}
                      InputProps={{
                        endAdornment: getPasswordInputAdornment(),
                      }}
                    />
                  </FormControl>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ borderRadius: "10px" }}
                  >
                    {loading ? t("wait") : t("ResetBtn")}
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </Paper>
      </Container>
    </Box>
  );
};
