import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
export const InputField = ({ id, label, type, value, onChange }) => {
    const { t } = useTranslation("contactUs");
    const theme = useTheme();

    return (
        <input
            type={type}
            id={id}
            name={id}
            placeholder={t(`contactUs.${label}`)}
            style={{
                padding: "20px 10px",
                marginTop: "10px",
                marginBottom: "10px",
                borderRadius: "5px",
                border: "none",
                outline: "none",
                fontSize: "17px",
                color: theme.palette.secondary.main,
                boxShadow: "0px 0px 5px 0px #F3F3F3",
                width: "100%",
            }}
            onChange={onChange}
            value={value}
        />
    );
};

export const TextArea = ({ id, label, value, onChange }) => {
    const { t } = useTranslation("contactUs");
    const theme = useTheme();

    return (
        <textarea
            id={id}
            name={id}
            rows={8}
            placeholder={t(`contactUs.${label}`)}
            style={{
                width: "100%",
                padding: "20px 10px",
                marginTop: "10px",
                marginBottom: "10px",
                borderRadius: "5px",
                border: "none",
                outline: "none",
                fontSize: "17px",
                color: theme.palette.secondary.main,
                boxShadow: "0px 0px 5px 0px #F3F3F3",
                height: "300px",
            }}
            onChange={onChange}
            value={value}
        />
    );
};
