import { FormControl, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
// Define constants for dropdown options
const TIME_PERIODS = ["Day", "Week", "Month", "Year"];
const CONDITIONS = ["New", "Used"];

const DropdownField = ({ id, name, label, value, options, handleChange }) => (
    <FormControl fullWidth>
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <Select
            id={id}
            name={name}
            label={label}
            value={value}
            fullWidth
            onChange={handleChange}
        >
            {options.map(option => (
                <MenuItem key={option} value={option}>
                    {option}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);

export const ProductFields = ({ editValues, handleChange, categories }) => {
    const { t } = useTranslation("editProduct");
    return (
        <Stack spacing={2} sx={{ margin: "auto" }}>
            <TextField
                id="name"
                label={t("form.name")}
                variant="outlined"
                name="name"
                value={editValues?.name}
                onChange={handleChange}
            />
            <TextField
                id="price"
                label={t("form.price")}
                variant="outlined"
                name="price"
                value={editValues?.price}
                onChange={handleChange}
            />
            <TextField
                id="description"
                label={t("form.description")}
                variant="outlined"
                name="description"
                multiline
                rows={8}
                value={editValues?.description}
                onChange={handleChange}
            />
            <DropdownField
                id="per"
                name="per"
                label={t("form.per")}
                value={editValues?.per}
                options={TIME_PERIODS}
                handleChange={handleChange}
            />
            <DropdownField
                id="condition"
                name="condition"
                label={t("form.condition")}
                value={editValues?.condition}
                options={CONDITIONS}
                handleChange={handleChange}
            />
            <FormControl fullWidth>
                <InputLabel htmlFor="category">
                    {t("form.category")}
                </InputLabel>
                <Select
                    id="category"
                    name="category"
                    label="Category"
                    value={editValues?.category}
                    fullWidth
                    onChange={handleChange}
                >
                    {categories.map(category => (
                        <MenuItem key={category._id} value={category._id}>
                            {category.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth>
                <InputLabel htmlFor="available">
                    {t("form.available.label")}
                </InputLabel>
                <Select
                    id="available"
                    name="available"
                    label= {t("form.available.label")}
                    value={editValues?.available}
                    fullWidth
                    onChange={handleChange}
                >
                    <MenuItem value={true}>{t("form.available.open")}</MenuItem>
                    <MenuItem value={false}>{t("form.available.open")}</MenuItem>
                </Select>
            </FormControl>
        </Stack>
    );
};
