import { Form } from "formik";
import { FormControl, Button, InputAdornment, CircularProgress } from "@mui/material";
import { CustomTextField } from "../CustomTextField";
// icons
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PhoneIcon from '@mui/icons-material/Phone';
import { useTranslation } from "react-i18next";
import { useTogglePassword } from "../helper/passwordUtils";
export const SignUpFiled = ({ loading, values, handleChange, handleBlur, touched, errors, signUpmethod }) => {
    const { showPassword, getPasswordInputAdornment } = useTogglePassword();
    const { t } = useTranslation("signup");
    return (
        <Form>
            <FormControl fullWidth sx={{ mb: 1 }}>
                <CustomTextField
                    size='small'
                    variant="outlined"
                    id="username"
                    name="username"
                    placeholder={t("signUpinput.username")}
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.username && Boolean(errors.username)}
                    helperText={touched.username && errors.username}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PermContactCalendarIcon sx={{ fontSize: "15px" }} />
                            </InputAdornment>
                        ),
                    }}
                />

            </FormControl>

            <FormControl fullWidth sx={{ mb: 1 }}>
                <CustomTextField
                    size='small'
                    type={signUpmethod === "email" ? "email" : "tel"}
                    placeholder={signUpmethod === "email" ? t("signUpinput.email") : t("signUpinput.phone")}
                    variant="outlined"
                    id="identifier"
                    name="identifier"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                {signUpmethod === "email" ? <MailOutlineIcon sx={{ fontSize: "15px" }} /> : <PhoneIcon sx={{ fontSize: "15px" }} />}
                            </InputAdornment>
                        ),
                    }}
                />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 1 }}>
                <CustomTextField
                    size='small'
                    type={showPassword ? "text" : "password"}
                    placeholder={t("signUpinput.password")}
                    variant="outlined"
                    id="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    InputProps={{
                        endAdornment: getPasswordInputAdornment(),
                        startAdornment: (
                            <InputAdornment position="start">
                                <LockOpenIcon sx={{ fontSize: "15px" }} />
                            </InputAdornment>
                        ),
                    }}
                />
            </FormControl>

            <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                    borderRadius: "10px", py: 1, mt: 2,
                    '&.Mui-disabled': {

                        color: 'white',
                        cursor: 'not-allowed',
                    }
                }}
                disabled={loading}
            >
                {t("create-account")} {" "}
                {loading ? (
                    <CircularProgress
                        size={20}
                        sx={{ color: "#dcc4c4", marginLeft: "5px" }}
                    />
                ) : (
                    ""
                )}
            </Button>
        </Form>
    )
}
