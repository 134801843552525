import { Stack, Box, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { countrys } from "../../../helper/Countrys";
import { states } from "../../../helper/HandelStates";
import { CustomTextFiled } from "./CustomTextFiled";
import { selectBoxStyle } from "./SelectBoxStyle";
import { useTranslation } from "react-i18next";

const AddressSelect = ({ label, value, name, onChange, options }) => {
const { t } = useTranslation("settings");
  return (

    <FormControl fullWidth>
      <InputLabel>{t(`save-address.labels.${label}`)}</InputLabel>
      <Select
        fullWidth
        value={value}
        name={name}
        label={t(`save-address.labels.${label}`)}
        size='medium'
        onChange={onChange}
        sx={selectBoxStyle}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>{option}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
};

const AddressTextField = ({ label, value, name, onChange }) => {
  const { t } = useTranslation("settings");
  return (
    <FormControl fullWidth>
      <CustomTextFiled
        name={name}
        label={t(`save-address.labels.${label}`)}
        variant="outlined"
        size="medium"
        value={value}
        onChange={onChange}
      />
    </FormControl>
  )
};

export const AddressInput = ({ user, handleChange }) => {
  const { t } = useTranslation("settings");
  const userAddress = user?.address || {};

  return (
    <Box spacing={2} p={2} sx={{ borderRadius: "10px", backgroundColor: "#F7F7F7" }}>
      <Typography variant="h6" sx={{ color: "secondary.main", fontWeight: "500", py: 2 }}>
        {t("save-address.home")}
      </Typography>
      <form>
        <Stack spacing={2}>
          <AddressSelect
            label="Country"
            value={userAddress.country}
            name="country"
            onChange={handleChange}
            options={countrys}
          />
          <AddressTextField
            label="Address"
            value={userAddress.address}
            name="address"
            onChange={handleChange}
          />
          <AddressTextField
            label="Apartment"
            value={userAddress.apartment}
            name="apartment"
            onChange={handleChange}
          />
          <AddressTextField
            label="City"
            value={userAddress.city}
            name="city"
            onChange={handleChange}
          />
          <AddressTextField
            label="Governorate"
            value={userAddress.governorate}
            name="governorate"
            onChange={handleChange}
          />
          <AddressSelect
            label="State"
            value={userAddress.state}
            name="state"
            onChange={handleChange}
            options={states(userAddress.country)}
          />
          <AddressTextField
            label="zipCode"
            value={userAddress.zipCode}
            name="zipCode"
            onChange={handleChange}
          />
        </Stack>
      </form>
    </Box>
  );
};
