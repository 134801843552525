import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useGetFeesAndTax } from './hooks/useGetFeesAndTax';

export const PaymentInfo = ({ transaction }) => {
    const { additionalFees, tax } = useGetFeesAndTax();
    const { t } = useTranslation("payment");

    // Calculate the number of units based on the rental period
    const startDate = dayjs(transaction.startDate).startOf('day');
    const endDate = dayjs(transaction.endDate).startOf('day');
    let numberOfUnits;
    switch (transaction?.productID?.per) {
        case 'Week':
            numberOfUnits = endDate.add(1, 'day').diff(startDate, 'week');
            break;
        case 'Month':
            numberOfUnits = endDate.add(1, 'day').diff(startDate, 'month');
            break;
        case 'Year':
            numberOfUnits = endDate.add(1, 'day').diff(startDate, 'year');
            break;
        default:
            numberOfUnits = endDate.add(1, 'day').diff(startDate, 'day');
    }

    // Calculate subtotal, total fees, and total cost
    const subtotal = transaction?.productID?.price * numberOfUnits;
    const totalFees = additionalFees;
    const totalCost = subtotal + totalFees + (subtotal + totalFees) * tax / 100;

    return (
        <Box sx={{ backgroundColor: "#F7F7F7", p: 2, borderRadius: "10px" }}>
            <Typography variant="h5" sx={{ color: "secondary.main", fontWeight: "bold" }}>
                {t("opderInfo.title")}
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: 2 }}>
                <Typography variant="body1" component="p">
                    {t("opderInfo.pricePer")} {transaction?.productID?.per}
                </Typography>
                <Typography variant="body1" component="p">
                    {transaction?.productID?.price}$
                </Typography>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: 2 }}>
                <Typography variant="body1" component="p">
                    {t("opderInfo.time")}
                </Typography>
                <Typography variant="body1" component="p">
                    {numberOfUnits} {transaction?.productID?.per}
                </Typography>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: 2 }}>
                <Typography variant="body1" component="p">
                    {t("opderInfo.subTotal")}
                </Typography>
                <Typography variant="body1" component="p">
                    {subtotal}$
                </Typography>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: 2 }}>
                <Typography variant="body1" component="p">
                    {t("opderInfo.additionalFees")}
                </Typography>
                <Typography variant="body1" component="p">
                    {totalFees}$
                </Typography>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: 2 }}>
                <Typography variant="body1" component="p">
                    {t("opderInfo.tax")}
                </Typography>
                <Typography variant="body1" component="p">
                    {tax}%
                </Typography>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: 2 }}>
                <Typography variant="body1" component="p">
                    {t("opderInfo.total")}
                </Typography>
                <Typography variant="body1" component="p">
                    {totalCost}$
                </Typography>
            </Box>
        </Box>
    );
};
