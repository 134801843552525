import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../../axios/axiosInstance";
import { useShowAlert } from "../../../../hooks/useShowAlert";
const useUserActions = (user) => {
  const { showAlert } = useShowAlert();
  const navigate = useNavigate();
  const [userDeleted, setUserDeleted] = useState(false);
  const [userBlocked, setUserBlocked] = useState(false);

  useEffect(() => {
    setUserDeleted(user.isDeleted);
    setUserBlocked(user.isBlocked);
  }, [user.isBlocked, user.isDeleted]);



  const handleGoToUserTransactions = () => {
    navigate(`/admins/user-transactions/${user._id}/${user.username}`);
  };

  const handleGoToEditUser = () => {
    navigate(`/admins/edit-user/${user._id}/${user.username}`);
  };

  const handleDeleteUser = (id) => {
    axiosInstance
      .delete(`/admin/user/${id}`)
      .then(() => {
        showAlert("success", "User Deleted Successfully");
        setUserDeleted(true);
      })
      .catch((err) => {
        showAlert("error", err.response.data.error);
      });
  };

  const handleRestoreUser = (userID) => {
    axiosInstance
      .post(`/admin/user/${userID}/restore`, {})
      .then(() => {
        showAlert("success", "User Restored Successfully");
        setUserDeleted(false);
      })
      .catch((err) => {
        showAlert("error", err.response.data.error);
      });
  };

  const handleBlockUser = (userID) => {
    axiosInstance
      .post(`/admin/user/${userID}/block`, {})
      .then(() => {
        showAlert("success", "User Blocked Successfully");
        setUserBlocked(true);
      })
      .catch((err) => {
        showAlert("error", err.response.data.error);
      });
  };

  const handleUnblockUser = (userID) => {
    axiosInstance
      .post(`/admin/user/${userID}/unblock`, {})
      .then(() => {
        showAlert("success", "User Unblocked Successfully");
        setUserBlocked(false);
      })
      .catch((err) => {
        showAlert("error", err.response.data.error);
      });
  };

  return {
    userDeleted,
    userBlocked,
    handleGoToUserTransactions,
    handleGoToEditUser,
    handleDeleteUser,
    handleRestoreUser,
    handleBlockUser,
    handleUnblockUser,
  };
};

export default useUserActions;
