import { Box, FormHelperText, Stack, Typography } from '@mui/material'
import { FieldArray, } from 'formik'
import { RenderAdditionalImages } from './RenderAdditionalImages';
import { RenderMainImageInput } from './RenderMainImageInput';
import { useTranslation } from 'react-i18next';
const UploadImages = ({ setFieldValue, errors, touched, values, handleImageChange, imageLoading }) => {
  const { t } = useTranslation("add-product");
  // handle delete main image
  const handleDeleteMainImage = () => {
    setFieldValue("pictures", values.pictures.filter((_, index) => index !== 0))
  }
  return (
    <>
    <Box sx={{ backgroundColor: "rgb(247,247,247)", borderRadius: "20px", p: 2 }}>
      <Stack spacing={2} sx={{ justifyContent: "center", alignItems: "center", position: "relative" }}>
         <RenderMainImageInput handleImageChange={handleImageChange} setFieldValue={setFieldValue} values={values} imageLoading={imageLoading} handleDeleteMainImage={handleDeleteMainImage} />
      </Stack>
    </Box>
    <Box>
      <Typography variant="h6" sx={{ mt: 2, color: "primary.main", fontFamily: "Poppins-Bold" }}>
        {t("uploadMore")}
      </Typography>
      <FieldArray name="pictures">
        {({ push, remove }) => (
          <>
            <RenderAdditionalImages push={push} remove={remove} values={values} handleImageChange={handleImageChange} setFieldValue={setFieldValue} imageLoading={imageLoading} />
            {touched.pictures && errors.pictures && (
              <FormHelperText sx={{ color: "red", pt: 1 }}>
                {errors.pictures}
              </FormHelperText>
            )}
          </>
        )}
      </FieldArray>
    </Box>
  </>
);
}

export default UploadImages;

