import { Box, Grid, Paper, Typography } from '@mui/material';

const ProductDisplay = ({ product }) => (
  <Grid container spacing={3}>
    <Grid item xs={12} md={4}>
      <Paper sx={{ width: '100%', height: '150px', borderRadius: '15px' }}>
        <img
          src={product ? product.pictures[0] : ''}
          alt="product"
          style={{ width: '100%', height: '100%', borderRadius: '15px', objectFit: 'contain' }}
        />
      </Paper>
    </Grid>
    <Grid item xs={12} md={8}>
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" sx={{ color: 'secondary.main', fontFamily: 'Poppins-Bold' }}>
          {product ? product.name : ''}
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          {product ? product.description : ''}
        </Typography>
      </Box>
    </Grid>
  </Grid>
);

export default ProductDisplay;
