import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../axios/axiosInstance";
import { useTheme } from "@mui/material/styles";
import { UserProductsContext } from "../../context";
import { useContext } from "react";
import { ProductRow } from "./ProductRow";
import { useTranslation } from "react-i18next";
import { useShowAlert } from "../../hooks/useShowAlert";
export const ProductTable = ({ items }) => {
  const { showAlert } = useShowAlert();
  const { t } = useTranslation("user-products");
  const { setUpdate } = useContext(UserProductsContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const LinkStyle = {
    textDecoration: "none",
    color: theme.palette.primary.main,
  };

  const handleEdit = (id) => {
    navigate(`/editProduct/${id}`);
  };

  const handleDelete = (id) => {
    axiosInstance.delete(`/products/${id}`)
      .then(() => {
        setUpdate(); // update products after delete one
        showAlert("success", "Product deleted successfully");
      }).catch((err) => {
        if (err) {
          showAlert("error", err.response.data.error);
        }
      });
  };

  return (
    <TableContainer component={Box} sx={{ borderRadius: "10px", borderBottom: "1px solid rgb(247,247,247)", width: "100%" }}>
      <Table sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ backgroundColor: "rgb(209,209,209)" }}>
            {["Name", "Updated", "Created", "Status", "Category", "Edit", "Delete"].map((header) => (
              <TableCell key={header} align="center" sx={{ fontSize: "13px", color: "primary.main" }}>
                {t(`table.${header}`)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody sx={{ width: "100%" }}>

          {items.length > 0 ? (
            items.map((product, i) => (
              <ProductRow
                key={i}
                product={product}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                LinkStyle={LinkStyle}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={7}>
                <Box sx={{ textAlign: "center", color: "primary.main", width: "100%", fontWeight: "bold" }}>
                  {t("no-data")}
                </Box>
              </TableCell>
            </TableRow>
          )
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};