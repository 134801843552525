import { Stack, List, ListItem, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const FooterLinks = ({ title, links }) => (
  <div>
    <Typography variant="body1" sx={{ color: "white", fontWeight: "bolder" }}>
      {title}
    </Typography>
    <Stack>
      <List>
        {links.map((link) => (
          <ListItem sx={{ paddingLeft: "0px" }} key={link.text}>
            {link.external ? (
              <a href={link.to} style={{ textDecoration: "none", color: link.color }}>
                {link.text}
              </a>
            ) : (
              <Link to={link.to} style={{ textDecoration: "none", color: link.color }}>
                {link.text}
              </Link>
            )}
          </ListItem>
        ))}
      </List>
    </Stack>
  </div>
);

export default FooterLinks;
