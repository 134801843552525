import Navbar from "../components/general/header/Navbar";
import { Outlet, useLocation } from "react-router-dom";
import { Footer } from "../components/general/footer/Footer";
import { useEffect } from "react";
import { ScrollToTop } from "../components/general/ScrollToTop";
import { getUser } from "../redux/slices/AuthUser";
import axiosInstance from "../axios/axiosInstance";
import { useSelector } from "react-redux";
import dayjs from 'dayjs'; // Import Day.js
import useLogout from "../hooks/useLogOut";
export const Root = () => {
  const logout = useLogout();
  const location = useLocation();

   // Update path history whenever location.pathname changes
 useEffect(() => {
  const pathHistory = JSON.parse(localStorage.getItem("pathHistory")) || [];
  const currentPath = location.pathname;
  if (pathHistory.length === 0 || currentPath !== pathHistory[pathHistory.length - 1]) {
    const newHistory = [...pathHistory, currentPath].slice(-2); // Keep only the last two paths
    localStorage.setItem("pathHistory", JSON.stringify(newHistory));
  }
}, [location.pathname]);

  // check if pathname is change scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // check exp date of token and remove it if it is expired
  const { exp, id } = useSelector(getUser);
  useEffect(() => {
    const currentTime = dayjs();
    const expTime = dayjs.unix(exp);
    if (id) {
      if (expTime.isBefore(currentTime)) {
        logout("The session expired, try login again",true);
      } else {
        // set timeout to logout user after token expired
        const timeout = expTime.diff(currentTime, 'second');
        setTimeout(() => {
          logout("The session expired, try login again",true);
        }, timeout * 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // create an axios interceptor to check if user is authenticated or not
  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 403 || error.response.status === 401) {
        logout("The session expired, try login again",false);
      }
      return Promise.reject(error);
    }
  );

  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
      <ScrollToTop />
    </>
  );
};
