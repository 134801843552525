import { Box, Button, Stack, } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { InputField, TextArea } from "./CustomeElements";
import { useTranslation } from "react-i18next";
import { useShowAlert } from "../../hooks/useShowAlert";
export const ContactUsForm = () => {
    const { showAlert } = useShowAlert();
    const { t } = useTranslation("contactUs");
    const [formValues, setFormValues] = useState({ name: "", email: "", phone: "", message: "" });
    const [loading, setLoading] = useState(false);
    const handleChangeValues = (e) => {
        setFormValues({ ...formValues, [e.target.id]: e.target.value });
    };

    // handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        // check if input fields are empty or not
        if (!formValues.name || !formValues.email || !formValues.phone || !formValues.message) {
            showAlert("error", "Please fill all fields");
            return;
        }
        setLoading(true);
        const data = {
            service_id: import.meta.env.VITE_VERCEL_EMAIL_SERVICE_ID,
            template_id: import.meta.env.VITE_VERCEL_EMAIL_TEMPLATE_ID,
            user_id: import.meta.env.VITE_VERCEL_EMAIL_USER_ID,
            template_params: {
                'to_name': import.meta.env.VITE_VERCEL_RECEIVER_EMAIL_NAME,
                'from_name': formValues.name,
                'from_email': formValues.email,
                "replay_to": formValues.email,
                'phone': formValues.phone,
                'message': formValues.message
            }
        };
        axios.post('https://api.emailjs.com/api/v1.0/email/send', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            if (response.status === 200) {
                showAlert("success", "Message sent successfully");
                // Clear form values
                setFormValues({ name: "", email: "", phone: "", message: "" });
            }
        }).catch(() => {
            showAlert("error", "Failed to send message");
        }).finally(() => {
            setLoading(false);
        });

    };
    return (
        <Box py={8}>
            <form onSubmit={handleSubmit}>
                <Stack spacing={2} direction="column">
                    <InputField
                        id="name"
                        label="nameInput"
                        type="text"
                        value={formValues.name}
                        onChange={handleChangeValues}
                    />
                    <InputField
                        id="email"
                        label="emailInput"
                        type="email"
                        value={formValues.email}
                        onChange={handleChangeValues}
                    />
                    <InputField
                        id="phone"
                        label="phoneInput"
                        type="tel"
                        value={formValues.phone}
                        onChange={handleChangeValues}
                    />
                    <TextArea
                        id="message"
                        label="messageInput"
                        value={formValues.message}
                        onChange={handleChangeValues}
                    />
                    <Button variant="contained" sx={{ width: "fit-content", px: 4, py: 1 }} type="submit" disabled={loading}>
                        {loading ? t("contactUs.sending") : t("contactUs.sendBtn")}
                    </Button>
                </Stack>
            </form>
        </Box>
    );
}
