import { useEffect, useState } from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../axios/axiosInstance';
import { setAlertDetails } from '../redux/slices/AlertDetailsSlice';
import { SkeletonInCommentAndReviews } from '../components/general/loading/SkeletonInCommentAndReviews';
import ProductDisplay from '../components/add-edit-comment-review/ProductDisplay';
import CommentReviewForm from '../components/add-edit-comment-review/CommentReviewForm';
import { discussionValidationSchema } from '../validation/validationSchemas';
import { useTranslation } from 'react-i18next';

export const EditComment = () => {
  const { t } = useTranslation("comments-reviews");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { productID, commentID } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [contentComment, setContentComment] = useState("");

  useEffect(() => {
    setLoading(true);
    const fetchProduct = async () => {
      try {
        const res = await axiosInstance.get(`/products/${productID}`);
        setProduct(res.data.product);
      } catch {
        setProduct(null);
      } finally {
        setLoading(false);
      }
    };

    const fetchComment = async () => {
      try {
        const res = await axiosInstance.get(`/comments/${commentID}`);
        setContentComment(res.data.comment.content);
      } catch {
        setContentComment("");
      }
    };

    fetchProduct();
    fetchComment();
  }, [commentID, productID]);

  const handleSubmit = (values, { resetForm }) => {
    axiosInstance
      .put(`/comments/${commentID}`, { content: values.comment })
      .then(() => {
        setContentComment(values.comment);
        dispatch(setAlertDetails({ type: 'success', message: 'Comment updated successfully' }));
        navigate(`/product-detail/${productID}`);
        resetForm();
      })
      .catch((err) => {
        dispatch(setAlertDetails({ type: 'error', message: err.response.data.error }));
      });
  };

  return (
    <Box sx={{ pt: 8 }}>
      <Typography
        variant="h4"
        sx={{ color: 'primary.main', fontWeight: 'bold', textAlign: 'center', mb: 8 }}
      >
        {t("comment.edit.title")}
      </Typography>
      <Box sx={{ backgroundColor: 'text.main', py: 5 }}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={7}>
              {loading ? (
                <SkeletonInCommentAndReviews />
              ) : (
                <ProductDisplay product={product} />
              )}
            </Grid>
            <Grid item xs={12} sm={5}>
              <CommentReviewForm
                initialValues={{ comment: contentComment }}
                handleSubmit={handleSubmit}
                validationSchema={discussionValidationSchema}
                buttonText={t("comment.edit.title")}
                placeholder={t("comment.edit.placeholder")}
                loading={false}
                type="comment"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};
