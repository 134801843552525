import { Box, Container, Paper, Typography } from '@mui/material'
import { Formik } from 'formik'
import { useTheme } from "@mui/material/styles";
import { signUpWithReferralValidationSchema } from '../validation/validationSchemas';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import {  useSelector } from 'react-redux';
import axiosInstance from '../axios/axiosInstance';
import { FieldInput } from '../components/auth/SignUpWithReferral/FiledInput';
import useSendTokenToContact from '../components/auth/hooks/SendToken';
import { useTranslation } from 'react-i18next';
import { getUser } from '../redux/slices/AuthUser';
import { useShowAlert } from '../hooks/useShowAlert';
export const SignUpWithReferrals = () => {
  const { t } = useTranslation("referralSignup");
  const { sendTokenToEmail } = useSendTokenToContact();
  const { showAlert } = useShowAlert();
  const urlParams = new URLSearchParams(window.location.search);
  const referralCode = urlParams.get('referral'); // get referral code from url
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const AuthUser = useSelector(getUser); //get user from redux
  const initialValues = {
    username: "",
    email: "",
    password: "",
    repeatPassword: "",
    referral: referralCode || ""
  }

  // handle submit function
  const handleSubmit = (values) => {
    // if the user had already login in-app, it shows a message you should log out before creating a new login 
     if(AuthUser?.user){
      showAlert("error","you are already login in the app and should log out before making a new login")
      return 0;
     }
    setLoading(true)
    axiosInstance.post('/user/signup', {
      username: values.username,
      email: values.email,
      password: values.password,
      referral: values.referral
    }).then(() => {
      showAlert('success', 'Successfully signed up!')
      sendTokenToEmail(values.email)// send email confirmation code
    }).catch((err) => {
      showAlert('error', err.response.data.error)
    }).finally(() => {
      setLoading(false)
    })
  }
  return (
    <Box sx={{ pt: 8 }}>
      <Typography variant="h2" align="center" sx={{ color: "primary.main", fontWeight: "bold", mb: 8, fontSize: ["35px", "60px"] }}>
       {t("title")}
      </Typography>

      <Box sx={{ backgroundColor: "text.main", py: "100px" }}>
        <Container maxWidth="sm">
          <Paper elevation={3} sx={{ p: 4, borderRadius: 2, backgroundColor: "secondary.main", px: 5 }}>
            <Typography variant="h4" align="center" sx={{ color: "white", fontWeight: "bold", textTransform: "capitalize" }}>
             {t("subtitle.1")}
              <br />
              {t("subtitle.2")}
            </Typography>
            <Typography variant="body2" align="center" sx={{ color: "white", my: 2 }}>
              {t("subtitle.3")}
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={signUpWithReferralValidationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange, handleBlur, errors, touched }) => (
                <FieldInput values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched} loading={loading} />
              )}
            </Formik>

            <Typography variant="body2" sx={{ textAlign: "center", mt: 2, color: "white" }}>
              {t("agreement.1")}
              <Link to="/" style={{ color: "white", paddingLeft: "5px" }}>{t("agreement.2")}</Link>.
            </Typography>

            <Typography variant="body2" sx={{ textAlign: "center", mt: 3, color: "white" }}>
             {t("haveAccount.1")}{" "}
              <Link
                to="/login"
                style={{ color: theme.palette.primary.main, marginLeft: "3px" }}
              >
                {t("haveAccount.2")}
              </Link>
            </Typography>
          </Paper>
        </Container>
      </Box>
    </Box>
  )
}