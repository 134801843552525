import { Grid, Box, Button } from "@mui/material"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../axios/axiosInstance";
import { fetchUser, getUser } from '../../../redux/slices/AuthUser';
import { AddressInput } from "./AddressInput";
import { useTranslation } from "react-i18next";
import { SelectLocation } from "../../general/SelectLocation";
import { useShowAlert } from "../../../hooks/useShowAlert";
export const SaveAdress = () => {
    const { showAlert } = useShowAlert();
    const { t } = useTranslation("settings");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authUser = useSelector(getUser);
    const [user, setUser] = useState({
        address: {
            country: "",
            state: "",
            zipCode: "",
            apartment: "",
            city: "",
            address: ""
        },
        location: null

    });
    // handle change value
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser((prev) => ({
            ...prev,
            address: {
                ...prev.address,
                [name]: value
            }
        }));
    };
    // handle save
    const handleSave = async (e) => {
        e.preventDefault();
        setLoading(true);
        // if location is null
        if (!user.location) {
            showAlert("error", "Please select location on the map")
            return;
        }
        axiosInstance.put(`/user/${authUser?.id}`, user
        ).then(() => {
            showAlert("success", "Adress updated successfully")
            // fetch new user data after update
            dispatch(fetchUser());
            navigate(-1);
        }).catch(err => {
            showAlert("error", err.response.data.error)

        }).finally(() => {
            setLoading(false)
        });
    }
    // get user data
    useEffect(() => {
        setUser({
            address: authUser?.user?.address ? {
                country: authUser?.user?.address?.country || "",
                state: authUser?.user?.address?.state || "",
                zipCode: authUser?.user?.address?.zipCode || "",
                apartment: authUser?.user?.address?.apartment || "",
                city: authUser?.user?.address?.city || "",
                address: authUser?.user?.address?.address || "",
            } : {
                country: "",
                state: "",
                zipCode: "",
                apartment: "",
                city: "",
                address: ""
            },
            location: authUser?.user?.location || null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <AddressInput user={user} handleChange={handleChange} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <SelectLocation
                    location={user.location}
                    onLocationChange={(location) => setUser((prev) => ({ ...prev, location }))}
                    mapId="e8b1b7b3b3b4b3b3hgc"
                />
                <Box sx={{ mt: 2, px: 5 }}>
                    <Button onClick={handleSave} variant="contained" color="primary" sx={{ mt: 2 }} fullWidth disabled={loading}>
                        {t("save-address.save")}
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}

