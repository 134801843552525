import { Box, Button, Stack, TextField, } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../axios/axiosInstance';
import { EditAcountInfoSkeleton } from '../../general/loading/EditAcountInfoSkeleton';
import { fetchUser } from '../../../redux/slices/AuthUser';
import { useTranslation } from 'react-i18next';
import { useShowAlert } from '../../../hooks/useShowAlert';
export const FormAcountInfo = ({ userInfo }) => {
    const { showAlert } = useShowAlert();
    const { t } = useTranslation("settings");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { userId } = useParams();
    const [user, setUser] = useState(
        {
            username: "",
            displayName: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            email: "",
        }
    )
    useEffect(() => {
        setUser({
            username: userInfo?.username ? userInfo.username : "",
            displayName: userInfo?.displayName ? userInfo.displayName : "",
            firstName: userInfo?.firstName ? userInfo.firstName : "",
            lastName: userInfo?.lastName ? userInfo.lastName : "",
            phoneNumber: userInfo?.phoneNumber ? userInfo.phoneNumber : "",
            email: userInfo?.email ? userInfo.email : "",

        })
    }, [userInfo])

    const handleChange = (e) => {
        const { name, value } = e.target
        setUser({ ...user, [name]: value })
    }

    const handleSubmit = (e) => {
        setLoading(true)
        e.preventDefault();
        if (user.username === "") {
            showAlert("error", "username shoud not be empty")

            return;
        }
        axiosInstance.put(`/user/${userId}`, user
        ).then(() => {
            showAlert("success", "User updated successfully")

            // fetch new user data after update
            dispatch(fetchUser());

        }).catch(err => {
            showAlert("error", err.response.data.error)

        }).finally(() => {
            setLoading(false)
        })

    };
    return (

        <>
            {
                userInfo ? (
                    <Box>
                        <form>
                            <Stack spacing={2}>
                                {
                                    user.username &&
                                    <TextField
                                        name="username"
                                        label={t("acount-info.labels.username")}
                                        variant="outlined"
                                        size="small"
                                        value={`@${user?.username}`}
                                        onChange={handleChange}
                                        disabled
                                    />
                                }
                                <TextField
                                    name="displayName"
                                    label={t("acount-info.labels.displayName")}
                                    variant="outlined"
                                    size="small"
                                    value={user?.displayName}
                                    onChange={handleChange}
                                />
                                <TextField
                                    name="firstName"
                                    label={t("acount-info.labels.firstN")}
                                    variant="outlined"
                                    size="small"
                                    value={user?.firstName}
                                    onChange={handleChange}
                                />
                                <TextField
                                    name="lastName"
                                    label={t("acount-info.labels.lastN")}
                                    variant="outlined"
                                    size="small"
                                    value={user?.lastName}
                                    onChange={handleChange}
                                />
                                <TextField
                                    name="email"
                                    label={t("acount-info.labels.email")}
                                    variant="outlined"
                                    size="small"
                                    value={user?.email}
                                    onChange={handleChange}
                                />
                                <TextField
                                    name="phoneNumber"
                                    label={t("acount-info.labels.phone")}
                                    variant="outlined"
                                    size="small"
                                    value={user?.phoneNumber}
                                    onChange={handleChange}
                                />
                                <Button variant="contained" color="primary" type="submit" onClick={handleSubmit} disabled={loading}>
                                    {t("acount-info.save")}
                                </Button>
                            </Stack>

                        </form>
                    </Box>
                ) : (
                    <EditAcountInfoSkeleton />
                )
            }
        </>

    )
}
