import { FormControl, InputAdornment } from "@mui/material";
import { CustomTextField } from "../CustomTextField";
import { useTranslation } from "react-i18next";
export const InputField = ({ id, name, type, placeholder, value, onChange, onBlur, touched, error, startIcon, endIcon }) => {
    const { t } = useTranslation("referralSignup");
    return (
        <FormControl fullWidth sx={{ mb: 1 }}>
            <CustomTextField
                size='small'
                variant="outlined"
                id={id}
                name={name}
                type={type}
                placeholder={t(`form.${placeholder}`)}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={touched && Boolean(error)}
                helperText={touched && error}
                InputProps={{
                    startAdornment: startIcon && (
                        <InputAdornment position="start">
                            {startIcon}
                        </InputAdornment>
                    ),
                    endAdornment: endIcon,
                }}
            />
        </FormControl>
    )
};
