import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Root } from "./layouts/Root";
import { Home } from "./pages/Home";
import { Shop } from "./pages/Shop";
import { CategoriesPage } from "./pages/CategoriesPage";
import { AboutUs } from "./pages/AboutUs";
import { AddNewProduct } from "./pages/AddNewProduct";
import { ContactUs } from "./pages/ContactUs";
import { Profile } from "./pages/Profile";
import { Settings } from "./pages/Settings";
import { TransactionHistory } from "./pages/TransactionHistory";
import { DisplayMyProducts } from "./pages/DisplayMyProducts";
import { Chat } from "./pages/Chat";
import { Payment } from "./pages/Payment";
import { AcountInfo } from "./pages/AcountInfo";
import { SaveAddress } from "./pages/SaveAddress";
import { SignupPage } from "./pages/SignupPage";
import { LoginPage } from "./pages/LoginPage";
import { SendEmailResetPassword } from "./pages/SendEmailResetPassword.jsx"; 
import { SuccessPayment } from "./pages/SuccessPayment";
import { ResetPassword } from "./pages/ResetPassword";
import { VerificationPage } from "./pages/VerificationPage";
import { FavoriteProducts } from "./pages/FavoriteProducts";
import { FailedPayment } from "./pages/FailPayment";
import { ContactWithUser } from "./pages/ContactWithUser";
import { AddComment } from "./pages/AddComment";
import { EditComment } from "./pages/EditComment";
import { AddReviews } from "./pages/AddReviews";
import { EditReview } from "./pages/EditReview";
import { RentProfile } from "./pages/RentProfile";
import { EditProducts } from "./pages/EditProducts";
import { ProductDetails } from "./pages/ProductDetails";
import { PopularProducts } from "./pages/PopularProducts";
import { SendReferralsCode } from "./pages/SendReferralsCode";
import { SignUpWithReferrals } from "./pages/SignUpWithReferrals";
import { AddCategories } from "./pages/admin/AddCategories";
import { EditCategories } from "./pages/admin/EditCategories";
import { AllUsers } from "./pages/admin/AllUsers";
import { UserTransactionsPage } from "./pages/admin/UserTransactionsPage";
import { EditUserPage } from "./pages/admin/EditUserPage";
import { Admin } from "./layouts/Admin";
import { ShowAdminCategories } from "./pages/admin/ShowAdminCategories";
import { Configuration } from "./pages/admin/Configuration";
import { NotFound } from "./pages/NotFound";
import { ErrorBoundary } from "./ErrorBoundary";
import { useSelector } from "react-redux";
import { getUser } from "./redux/slices/AuthUser";
import { Faq } from "./pages/Faq";
import { TermsOfService } from "./pages/TermsOfService";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { GuaranteePolicy } from "./pages/GuaranteePolicy";
import { UsersReferred } from "./pages/admin/UsersReferred";
import { useState, useEffect } from "react";
import { Loading } from "./components/general/loading/LoadingApp";
import { Reports } from "./pages/admin/Reports.jsx";
export const Router = () => {
  const AuthUser = useSelector(getUser); //get user from redux
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (AuthUser.user === null) return setLoading(false); // this to prevent loading if user logout
    setLoading(true);
    const timer = setTimeout(() => setLoading(false), 500); // Simulate loading delay
    return () => clearTimeout(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AuthUser?.user?._id]);

  if (loading) {
    return <Loading />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Root />}>
          <Route index element={<ErrorBoundary><Home /></ErrorBoundary>} />
          <Route path="/about-us" element={<ErrorBoundary><AboutUs /></ErrorBoundary>} />
          <Route path="/faq" element={<ErrorBoundary><Faq /></ErrorBoundary>} />
          <Route path="/terms-of-service" element={<ErrorBoundary><TermsOfService /></ErrorBoundary>} />
          <Route path="/privacy-policy" element={<ErrorBoundary><PrivacyPolicy /></ErrorBoundary>} />
          <Route path="/guarantee-policy" element={<ErrorBoundary><GuaranteePolicy /></ErrorBoundary>} />
          <Route path="/categories" element={<ErrorBoundary><CategoriesPage /></ErrorBoundary>} />
          <Route path="/shop" element={<ErrorBoundary><Shop /></ErrorBoundary>} />
          <Route path="/popular-products" element={<ErrorBoundary><PopularProducts /></ErrorBoundary>} />
          <Route path="/product-detail/:productID" element={<ErrorBoundary><ProductDetails /></ErrorBoundary>} />
          <Route path="/contactWithUser/:userId/:username" element={<ErrorBoundary><ContactWithUser /></ErrorBoundary>} />
          <Route path="/contact-us" element={<ErrorBoundary><ContactUs /></ErrorBoundary>} />
          <Route path="/rent-profile/:userId" element={<ErrorBoundary><RentProfile /></ErrorBoundary>} />
          <Route path="/add-comment/:productID" element={<ErrorBoundary><AddComment /></ErrorBoundary>} />
          <Route path="/add-review/:productID" element={<ErrorBoundary><AddReviews /></ErrorBoundary>} />
          <Route path="/user/signup" element={<ErrorBoundary><SignUpWithReferrals /></ErrorBoundary>} />
          {AuthUser?.user ? (
            <>
              <Route path="/send-referral-code" element={<ErrorBoundary><SendReferralsCode /></ErrorBoundary>} />
              <Route path="/chat" element={<ErrorBoundary><Chat /></ErrorBoundary>} />
              <Route path="/my-products" element={<ErrorBoundary><DisplayMyProducts /></ErrorBoundary>} />
              <Route path="/success-payment" element={<ErrorBoundary><SuccessPayment /></ErrorBoundary>} />
              <Route path="/fail-payment" element={<ErrorBoundary><FailedPayment /></ErrorBoundary>} />
              <Route path="/save-address" element={<ErrorBoundary><SaveAddress /></ErrorBoundary>} />
              <Route path="/account-info/:userId" element={<ErrorBoundary><AcountInfo /></ErrorBoundary>} />
              <Route path="/settings" element={<ErrorBoundary><Settings /></ErrorBoundary>} />
              <Route path="/edit-comment/:productID/:commentID" element={<ErrorBoundary><EditComment /></ErrorBoundary>} />
              <Route path="/add-new-product" element={<ErrorBoundary><AddNewProduct /></ErrorBoundary>} />
              <Route path="/favorite-products/:userId" element={<ErrorBoundary><FavoriteProducts /></ErrorBoundary>} />
              <Route path="/profile" element={<ErrorBoundary><Profile /></ErrorBoundary>} />
              <Route path="/editProduct/:id" element={<ErrorBoundary><EditProducts /></ErrorBoundary>} />
              <Route path="/transactions" element={<ErrorBoundary><TransactionHistory /></ErrorBoundary>} />
              <Route path="/payment/:transactionID" element={<ErrorBoundary><Payment /></ErrorBoundary>} />
              <Route path="/edit-review/:productID/:reviewID" element={<ErrorBoundary><EditReview /></ErrorBoundary>} />
            </>
          ) : (
            <>
              <Route path="/signup" element={<ErrorBoundary><SignupPage /></ErrorBoundary>} />
              <Route path="/login" element={<ErrorBoundary><LoginPage /></ErrorBoundary>} />
              <Route path="/sendToken" element={<ErrorBoundary><SendEmailResetPassword /></ErrorBoundary>} />
              <Route path="/reset-password" element={<ErrorBoundary><ResetPassword /></ErrorBoundary>} />
              <Route path="/verification/:method" element={<ErrorBoundary><VerificationPage /></ErrorBoundary>} />
            </>
          )}
        </Route>

        {AuthUser?.user?.role === "Admin" ? (
          <Route path="/admins" element={<ErrorBoundary><Admin /></ErrorBoundary>}>
            <Route path="/admins/categories" element={<ErrorBoundary><ShowAdminCategories /></ErrorBoundary>} />
            <Route path="/admins/add-categories" element={<ErrorBoundary><AddCategories /></ErrorBoundary>} />
            <Route path="/admins/edit-categories/:id" element={<ErrorBoundary><EditCategories /></ErrorBoundary>} />
            <Route index element={<ErrorBoundary><AllUsers /></ErrorBoundary>} />
            <Route path="/admins/user-transactions/:userID/:username" element={<ErrorBoundary><UserTransactionsPage /></ErrorBoundary>} />
            <Route path="/admins/edit-user/:userID/:username" element={<ErrorBoundary><EditUserPage /></ErrorBoundary>} />
            <Route path="/admins/configuration" element={<ErrorBoundary><Configuration /></ErrorBoundary>} />
            <Route path="/admins/Referred-Users" element={<ErrorBoundary><UsersReferred /></ErrorBoundary>} />
            <Route path="/admins/Reports" element={<ErrorBoundary><Reports /></ErrorBoundary>} />
          </Route>
        ) : null}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
