import { Button, CircularProgress, FormControl } from '@mui/material'
import { Form } from 'formik'
import { Link } from 'react-router-dom'
import { CustomTextField } from '../CustomTextField'
// import icons 
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PhoneIcon from '@mui/icons-material/Phone';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useTogglePassword } from '../helper/passwordUtils';
export const LoginFiled = ({ loading, loginMethod, values, handleChange, handleBlur, touched, errors }) => {
    const { showPassword, getPasswordInputAdornment } = useTogglePassword();
    const { t } = useTranslation("login");
    const theme = useTheme();

    return (
        <Form>
            <FormControl fullWidth margin="normal">
                <FormControl fullWidth sx={{ mb: 1 }}>
                    <CustomTextField
                        size='small'
                        type={loginMethod === "email" ? "email" : "tel"}
                        placeholder={loginMethod === "email" ? t("logininput.email") : t("logininput.phone")}
                        variant="outlined"
                        id="identifier"
                        name="identifier"
                        value={values.identifier}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        InputProps={{
                            startAdornment: loginMethod === "email" ? <MailOutlineIcon sx={{ fontSize: "15px", mr: 1 }} /> : <PhoneIcon sx={{ fontSize: "15px", mr: 1 }} />
                        }}
                    />

                </FormControl>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 1 }}>
                <CustomTextField
                    size='small'
                    type={showPassword ? "text" : "password"}
                    placeholder={t("logininput.password")}
                    variant="outlined"
                    id="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    InputProps={{
                        endAdornment:
                            getPasswordInputAdornment(),
                        startAdornment: <LockOpenIcon sx={{ fontSize: "15px", mr: 1 }} />
                    }}
                />
            </FormControl>
            <Link to="/sendToken" style={{ color: theme.palette.text.main, textDecoration: "none" }}>{t("forgot")}</Link>
            <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                    borderRadius: "10px", py: 1, mt: 2,
                    '&.Mui-disabled': {

                        color: 'white',
                        cursor: 'not-allowed',
                    }
                }}
                disabled={loading}
            >
                {t("login")} {"   "} { loading ?  <CircularProgress size={20} sx={{ color: "#dcc4c4", marginLeft: "5px" }} />: " " }
            </Button>


        </Form>
    )
}
