// hooks/useProductForm.js
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../axios/axiosInstance";
import { getUser } from "../../../redux/slices/AuthUser";
import { uploadImage } from "../../../helper/UploadImage";
import { useShowAlert } from "../../../hooks/useShowAlert";
const useProductForm = () => {
  const [imageLoading, setImageLoading] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const Authuser = useSelector(getUser);
  const { showAlert } = useShowAlert();

  const handleImageChange = async (event, index, setFieldValue) => {
    const selectedImage = event.target.files[0];
    setImageLoading(prevState => ({ ...prevState, [index]: true }));
    try {
      const imageUrl = await uploadImage(selectedImage);
      if (imageUrl) {
        setFieldValue(`pictures.${index}`, imageUrl);
      } else {
        showAlert("error", "Image upload failed");
      }
    } finally {
      setImageLoading(prevState => ({ ...prevState, [index]: false }));
    }
  };

  const onSubmit = async (values, { resetForm }) => {
    setLoading(true);
    try {
      const finalValues = {
        ...values,
        userID: Authuser?.id,
      };

      if (!Authuser?.user.location?.lat || !Authuser?.user.location?.lng) {
        navigate(`/save-address`);
        localStorage.setItem(
          "productSaved",
          JSON.stringify(values)
        );
        showAlert("error", "Please Update Your location from map");
        return;
      }
      await axiosInstance.post("/products", finalValues);
      showAlert("success", "Product added successfully");
      localStorage.removeItem("productSaved");
      resetForm();
    } catch (error) {
      showAlert("error", error.response.data.error);
    } finally {
      setLoading(false);
    }
  };

  return { handleImageChange, imageLoading, onSubmit, loading };
};

export default useProductForm;
