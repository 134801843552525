import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../axios/axiosInstance";
import { useShowAlert } from "../../../hooks/useShowAlert";
export const useResetPassword = () => {
  const { showAlert } = useShowAlert();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleNavigateToLoginPage = () => {
    navigate("/login");
  };


  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await axiosInstance.post("/user/reset-password", values);
      showAlert("success", "Password Reset Successfully");
      handleNavigateToLoginPage();
    } catch (err) {
      showAlert("error", err.response.data.error);
    } finally {
      setLoading(false);
    }
  };

  return {
    handleSubmit,
    loading,
  };
};
