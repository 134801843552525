import { FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';
import { Field } from 'formik';
import { styled } from "@mui/material/styles";
import { useTranslation } from 'react-i18next';

const CustomTextField = styled(TextField)({
    color: "secondary.main",
    '& .MuiOutlinedInput-root': {
        backgroundColor: "white",
        '& fieldset': {
            borderColor: 'transparent',
        },
        '&:hover fieldset': {
            borderColor: 'transparent',
            borderRadius: "20px",
        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent',
        },
    },
    '& .MuiFormLabel-root': {
        color: 'black',
    },
});

const DynamicField = ({ fieldConfig, setFieldValue, values, errors, touched }) => {
    const { t } = useTranslation("add-product");
    const { name, label, type, options, ...rest } = fieldConfig;

    const handleChange = (event) => {
        setFieldValue(name, event.target.value);
    };

    const getErrorText = (error) => {
        return error ? t(`validation.${error}`) : '';
    };

    switch (type) {
        case 'text':
            return (
                <Field
                    as={CustomTextField}
                    name={name}
                    label={t(label)}
                    value={values[name]}
                    fullWidth
                    onChange={handleChange}
                    error={touched[name] && Boolean(errors[name])}
                    helperText={touched[name] && getErrorText(errors[name])}
                    {...rest}
                />
            );

        case 'select':
            return (
                <FormControl fullWidth error={touched[name] && Boolean(errors[name])}>
                    <InputLabel htmlFor={name} sx={{ color: "secondary.main" }}>{t(label)}</InputLabel>
                    <Field
                        as={Select}
                        name={name}
                        value={values[name]}
                        fullWidth
                        onChange={handleChange}
                        sx={{
                            color: "secondary.main",
                            backgroundColor: "white",
                            borderRadius: "10px",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": { border: 0 },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": { border: 0 },
                        }}
                    >
                        {options.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {t(`${option.label}`)}
                            </MenuItem>
                        ))}
                    </Field>
                    {touched[name] && errors[name] && <FormHelperText>{getErrorText(errors[name])}</FormHelperText>}
                </FormControl>
            );

        case 'radio':
            return (
                <FormControl fullWidth error={touched[name] && Boolean(errors[name])}>
                    <RadioGroup
                        name={name}
                        value={values[name]}
                        onChange={handleChange}
                        sx={{ pb: 2 }}
                    >
                        {options.map(option => (
                            <FormControlLabel key={option.value} value={option.value} control={<Radio sx={{ color: "secondary.main" }} />} label={option.label} />
                        ))}
                    </RadioGroup>
                    {touched[name] && errors[name] && <FormHelperText>{getErrorText(errors[name])}</FormHelperText>}
                </FormControl>
            );

        default:
            return null;
    }
};

export default DynamicField;
