import { useEffect, useState } from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../axios/axiosInstance';
import { setAlertDetails } from '../redux/slices/AlertDetailsSlice';
import { getUser } from '../redux/slices/AuthUser';
import ProductDisplay from '../components/add-edit-comment-review/ProductDisplay'; 
import CommentReviewForm from '../components/add-edit-comment-review/CommentReviewForm'; 
import { discussionValidationSchema } from '../validation/validationSchemas';
import { SkeletonInCommentAndReviews } from '../components/general/loading/SkeletonInCommentAndReviews'; 
import { useTranslation } from 'react-i18next';
export const AddComment = () => {
  const { t } = useTranslation("comments-reviews");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector(getUser)?.id;
  const { productID } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`/products/${productID}`)
      .then((res) => setProduct(res.data.product))
      .catch(() => setProduct(null))
      .finally(() => setLoading(false));
  }, [productID]);

  const handleSubmit = (values, { resetForm }) => {
    const commentDetails = {
      content: values.comment,
      userId,
      productId: productID,
    };
    axiosInstance
      .post('/comments', commentDetails)
      .then(() => {
        dispatch(setAlertDetails({ type: 'success', message: 'Comment added successfully' }));
        navigate(`/product-detail/${productID}`);
      })
      .catch((err) => {
        dispatch(setAlertDetails({ type: 'error', message: err.response.data.error }));
      });
    resetForm();
  };

  return (
    <Box sx={{ pt: 8 }}>
      <Typography
        variant="h4"
        sx={{ color: 'primary.main', fontWeight: 'bold', textAlign: 'center', mb: 8 }}
      >
        {t("comment.title")}
      </Typography>
      <Box sx={{ backgroundColor: 'text.main', py: 5 }}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={7}>
              {loading ? (
                <SkeletonInCommentAndReviews />
              ) : (
                <ProductDisplay product={product} />
              )}
            </Grid>
            <Grid item xs={12} sm={5}>
              <CommentReviewForm
                initialValues={{ comment: '' }}
                handleSubmit={handleSubmit}
                validationSchema={discussionValidationSchema}
                buttonText={t("comment.add.title")}
                placeholder={t("comment.add.placeholder")}
                loading={false}
                type="comment"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};
