import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../../axios/axiosInstance";
import { setOpenDialog } from "../../../../redux/slices/userDetailsDialogSlice";
import { fetchUser, getUser } from "../../../../redux/slices/AuthUser";
import { uploadImage } from "../../../../helper/UploadImage";
import { useShowAlert } from "../../../../hooks/useShowAlert";

const useSubmitData = () => {
  const { showAlert } = useShowAlert();
  const authUser = useSelector(getUser);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    dispatch(setOpenDialog(false));
  };

  const saveUserDetails = async (values) => {
    try {
      await axiosInstance.put(`/user/${authUser?.id}`, values);
      showAlert("success", "User Details Updated Successfully");
      handleClose();
    } catch (err) {
      showAlert("error", err.response?.data?.error || "An error occurred");
    } finally {
      dispatch(fetchUser());
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      if (values.profilePicture) {
        values.profilePicture = await uploadImage(values.profilePicture);
      } else {
        values.profilePicture = authUser?.user.profilePicture;
      }
      await saveUserDetails(values);
    } catch (error) {
      showAlert("error", error.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    handleSubmit,
    handleClose,
  };
};

export default useSubmitData;
