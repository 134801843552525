import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Stack, Typography } from "@mui/material";
import { EditStatusTransaction } from "./EditStatusTransaction";
import { formatDate } from "../../../helper/formatDate";
import { getStatusColor } from "./helper/getColorDependenStatus";
import { useState } from "react";

export const TransactionsTable = ({ items }) => {
    const [transactions, setTransactions] = useState(items);

    // update states for specific transaction when status is updated 
    const updateTransactionStatus = (transactionID, status) => {
        const updatedTransactions = transactions.map((transaction) => {
            if (transaction._id === transactionID) {
                return { ...transaction, status };
            }
            return transaction;
        });
        setTransactions(updatedTransactions);
    };

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center" sx={{ fontSize: ["13px", "16px", "18px"], fontWeight: "bold" }}>
                            Name
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: ["13px", "16px", "18px"], fontWeight: "bold" }}>
                            Start At
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: ["13px", "16px", "18px"], fontWeight: "bold" }}>
                            End At
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: ["13px", "16px", "18px"], fontWeight: "bold" }}>
                            Status
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: ["13px", "16px", "18px"], fontWeight: "bold" }}>
                            Renter
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: ["13px", "16px", "18px"], fontWeight: "bold" }}>
                            Owner
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transactions.map((transaction, i) => (
                        <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                            <TableCell align="center">
                                {transaction?.productID?.name}
                            </TableCell>
                            <TableCell align="center">
                                {formatDate(transaction?.startDate)}
                            </TableCell>
                            <TableCell align="center">
                                {formatDate(transaction?.endDate)}
                            </TableCell>
                            <TableCell align="center" sx={{ color: getStatusColor(transaction?.status) }}>
                                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                    <Typography sx={{ fontSize: ["13px", "16px"] }} component="span">{transaction?.status}</Typography>
                                    <Typography component="span" sx={{ fontSize: ["13px"] }}>/</Typography>
                                    <EditStatusTransaction transactionID={transaction?._id} updateTransactionStatus={updateTransactionStatus} />
                                </Stack>
                            </TableCell>
                            <TableCell align="center">
                                {transaction?.userID?.username}
                            </TableCell>
                            <TableCell align="center">
                                {transaction?.ownerID?.username}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
