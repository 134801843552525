import { Container, Typography, Box } from '@mui/material';

export const TermsOfService = () => {
    return (
        <Box>
            <Typography
                variant="h4"
                align="center"
                sx={{ color: 'primary.main', fontFamily: 'Poppins-Bold', fontSize: ["18px", "35px"], my: 8 }}
            >
                Terms & Condition
            </Typography>
            <Box sx={{ backgroundColor: "text.main" }}>
                <Container maxWidth="xl" sx={{ py: 8 }}>
                    <Box>
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2, fontFamily: "Poppins-Bold" }}>
                            Direct Rent Terms of Use
                        </Typography>
                        <Typography variant="body2" sx={{ color: "secondary.main", mb: 2 }}>
                            Welcome to DirectRent.com a peer-to-peer Personal Idle Goods marketplace and online service provided by Gearbear LTD
                        </Typography>
                        <Typography variant="body1" sx={{ color: "primary.main", mb: 2 }}>
                            The Services are not intended for children under the age of 18 and no person under the age of 18 may
                            <br />
                            download the App or otherwise use the Services.
                        </Typography>
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2 }}>
                            Please read this entire TOU carefully before downloading, using, and during the use of, any of the services or
                            any listed or lent items. These TOU contain very important information regarding your rights, obligations, as
                            well as liability exclusions and limitations, including Disclaimers regarding the services, listed items and lent
                            items: limitations, exclusions and conditions, including with respect to product liability, personal injury, and
                            lost, stolen or damaged items; an indemnity from you; a forum selection clause: and a waiver of class action
                            disputes clause. This TOU is effective upon acceptance. When you register for a registered user account with
                            Direct Rent, you accept these TOU by checking the appropriate box and acknowledging that you have read,
                            understood and accept these TOU. If you do not register a registered user account with Direct Rent, these TOU
                            are effective through the use of the services, including Direct Rent’s website, or by downloading Direct Rent’s
                            app. If you do not agree to be bound by any part of these TOU, you must not use the services and must cease
                            any use of the services immediately.
                        </Typography>
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2 }}>
                            These TOU are between You (i.e. the end-user) (“You”) and Just Direct Rent Inc. (“DirectRent”), and they
                            govern Your use of DirectRent’s Services (as defined below). In these TOU, the word “use” means “access or
                            use”.
                            Direct Rent makes available, via Website and its App, an online service that allows owners of goods to rent out
                            their goods, and to sell their goods, to renters and buyers.
                            You may contact DirectRent email at suport@Directrent.co.eg with questions about these TOU.
                        </Typography>
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2 }}>
                            other types of content.
                            Lender: means a Registered User who has listed an item for Borrow in a Listing.
                            Lend: means to rent a Listed Item, or Listed Item(s), to a Borrower.
                            Lent Item: means a Lender’s item that has been Lent to a Borrower.
                            Borrowing Request Period: means the time period starting from the time when an item is requested to be
                            Borrowed by a Borrower (as determined by Direct Rent in its sole discretion), within which a Lender may
                            decide whether to confirm or reject that request, as stated on the Services. Different Booking Request Periods
                            may apply in different places.
                            Borrowing Period: means the maximum number of days
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2, fontFamily: "Poppins-Bold" }}>
                            SELECT DEFINITIONS:
                        </Typography>
                        <Typography variant="body2" sx={{ color: "secondary.main", mb: 2 }}>
                            App: means the mobile applications that Direct Rent makes available for use of the Services.
                            Borrower: means a Registered User who requests to Borrow, or actually Borrows, or to buy, any Listed Item(s).
                            Borrow: means to rent a Listed Item, or Listed Item(s), from a Lender.
                            Direct Rent Content: other than User Content, any Content available on the Services or used to create and
                            operate the Services, as well as the selection and arrangement of such Content.
                            Content: means any text, information, materials, data, video, graphics, pictures, music, logos, marks, designs,
                            software (excluding the App), hyperlinks, embedded links, URLs, and agreed to by Borrower and Lender that a
                            Listed Item may be Borrowed by Borrow, which period must be at least 24 hours.
                            Borrowing Rate: means the amount of monies that Borrower must pay Lender for 24-hour increment over
                            which the Listed Item will be Borrowed. The Borrowing Rate accrues for each 24 hour increment in which the
                            item is Lent until the item is returned by Borrower to Lender.
                            Listed Item: means a Lender’s item that has been listed in a Listing for Lending.
                            Listing: means a Lender’s listing on the Services of a Listed Item for Borrow.
                            Registered User: means a User that has set-up a Registered User Account (as defined in Section 3).
                            Services: means the Website and the App, as well as any software, platforms, digital services, features, tools,
                            and functionalities, as well as User Content and Direct Rent Content made available via or accessible through
                            any of the foregoing.
                            User: means any User of the Services.
                            User Content: means any Content that You, or other Users, upload, submit, post, display or otherwise make
                            available on the Services via the App.
                            Website: means Direct Rent’s website for the Services, currently located at www.directrent.co.eg
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2, fontFamily: "Poppins-Bold" }}>
                            YOUR AGE AND ELIGIBILITY TO USE THE SERVICES; VERIFICATION OF USERS
                        </Typography>
                        <Typography variant="body2" sx={{ color: "secondary.main", mb: 2 }}>
                            The Services are not intended for children under the age of 18 and no person under the age of 18 may
                            download the App or otherwise use the Services.
                            At present, only Registered Users in Egypt may Lend or Borrow via the Services. If You download the App or
                            otherwise use the Services, You represent and warrant to Direct Rent that You are at least 18 years of age.
                            Direct Rent may make use of any of the Services subject to certain conditions, including completing a
                            verification process. Please see Section 3 below for more information.
                        </Typography>
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2, fontFamily: "Poppins-Bold" }}>
                            SCOPE OF DIRECT RENT SERVICES
                        </Typography>
                        <Typography variant="body2" sx={{ color: "secondary.main", mb: 2 }}>
                            In no event will Direct Rent be responsible for and, to the maximum extent permitted by law, Direct Rent
                            disclaims and excludes all liability arising from the conduct of borrowers and lenders, use of listed items and
                            lent items by borrowers and lenders, and transactions between borrowers and lenders.
                            The Services provide Users with an online peer-to-peer marketplace that enables Lenders and Borrowers to
                            Lend and Borrow Listed Items, and for Lenders and Borrowers to communicate and transact directly to
                            facilitate such lending and borrowing. The Services merely facilitate Lending and Borrowing, and Direct Rent
                            does not own, create, lend, provide, control, manage, offer, deliver, insure, or supply any Listings or Listed
                            Items. For certainty, Direct Rent is not a rental broker, agent or insurer. When Lenders and Borrowers arrange
                            for a Listed Item to be Borrowed/Lent, they are entering into a contract directly with each other. Direct Rent is
                            not and does not become a party to or other participant in any contractual relationship between Registered
                            Users, nor is Direct Rent an insurer. Direct Rent is not acting as an agent in any capacity for any Registered
                            User.
                        </Typography>
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2, fontFamily: "Poppins-Bold" }}>
                            NO ENDORSEMENT; VERIFICATION
                        </Typography>
                        <Typography variant="body2" sx={{ color: "secondary.main", mb: 2 }}>
                            Direct Rent does not endorse any Users. Although these TOU require Users to provide accurate information,
                            Direct Rent may, but has no obligation to, verify or attempt to confirm any information provided by Users or
                            any User’s purported identity. You are solely responsible for determining the identity and suitability of other
                            Users who You contact via the Services. Any references to a User being "verified" or "connected" (or similar
                            language) only indicate that the User has completed a relevant verification or identification process, and
                            does not represent anything else. Any such description is not an endorsement, certification or guarantee by
                            Direct Rent about any User, including of the User's identity and whether the User is trustworthy, safe or suitable.
                            Except as expressly specified in Section 11, Direct Rent is not responsible for any damage or harm resulting
                            from your interactions with other Users. Direct Rent, is not responsible for any damage or harm resulting from
                            Your interactions with other Users, including any Renting and Selling. Direct Rent does not assume any
                            responsibility for the confirmation of any Registered User’s identity. Notwithstanding the above, for
                            transparency and fraud prevention purposes, and as permitted by applicable laws, Direct Rent may, but does
                            not have any obligation to undertake screenings, checks and processes designed to help verify or check the
                            identities and/or backgrounds of users and Listed Items. Such checks may be performed by Direct Rent and/
                            or third party services and may include screening Registered Users based on information provided by
                            Registered Users and/or requested by Direct Rent, screening against third party databases or other sources,
                            requesting reports from service providers, and reviewing your government identification, date of birth, and
                            other similar information. Such verification may include (i) for Users in New Zealand, to the extent permitted
                            by applicable laws and if Direct Rent has sufficient information to identify a User, obtaining reports from public
                            records of criminal convictions or sex offender registrations of the User; and (ii) for Users outside New
                            Zealand, to the extent permitted by applicable laws and if we have sufficient information to identify a User,
                            obtaining the local version of background or registered sex offender checks in Direct Rent’s sole discretion.
                            You hereby authorize Direct Rent to request, receive, use, and store such information.
                        </Typography>
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2, fontFamily: "Poppins-Bold" }}>
                            PRIVACY POLICY
                        </Typography>
                        <Typography variant="body2" sx={{ color: "secondary.main", mb: 2 }}>
                            In order to operate and make the Services available, Direct Rent collects, uses and discloses certain personal
                            information about You. Direct Rent collects, uses, discloses and protects that information as described in
                            Direct Rent’s Privacy Policy, which You can find at Privacy policy - Direct Rent, which is hereby incorporated
                            into and forms a part of these TOU. Your privacy is important to Direct Rent and Direct Rent’s Privacy Policy
                            explains how Direct Rent collects, uses, discloses and protects Your personal information.
                            You agree and authorize Direct Rent to use Your personal information, such as Your full name and date of
                            birth, to conduct the verifications described in Section 3 above.
                            As part of Lending/Borrowing, You may obtain personal information of another User. Without obtaining prior
                            permission, this personal information shall only be used for that specific transaction or for communications
                            regarding Borrowing/Lending with another User.
                        </Typography>
                        <Typography variant="body1" sx={{ color: "secondary.main", mb: 2, fontFamily: "Poppins-Bold" }}>
                            ELIGIBLE MOBILE DEVICES
                        </Typography>
                        <Typography variant="body2" sx={{ color: "secondary.main", mb: 2 }}>
                            The App is made available to You by Direct Rent free of charge through Google Play (owned or operated by
                            Google Inc. (“Google”)) and Apple Inc.’s (“Apple”) App Store (owned and operated by Apple), for use on an
                            eligible mobile device of Yours that complies with these TOU and is owned or controlled by You ("Your Mobile
                            Device"). Without limitation, Direct Rent does not endorse Your Mobile Device and provides no representations,
                            warranties or conditions regarding Your Mobile Device.
                            You understand and acknowledge that the App, and the Services used through any mobile device by any
                            browser, are intended for use on mobile devices using the Android or iOS operating systems, or such other
                            devices or operating systems as may be approved by Google or Apple, in each case as determined by
                            Google, Apple or the telecommunication provider for Your Mobile Device, in their sole discretion. You will not
                            use the App, or any browser to use the Services, on a mobile device or an operating system (including an
                            Android or iOS operating system and whether approved by Google or Apple) that has been, in each case,
                            modified or customized in any way (including “jailbroken”, “rooted”, “bootleg unlocked”) by anyone other than
                            the original equipment manufacturer (or original licensor of the operating system software, as applicable), an
                            authorized distributor or reseller thereof, the applicable telecommunication provider or anyone else acting
                            with the permission or on behalf of any of the foregoing.
                        </Typography>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
};
