import { TableCell, TableRow, Button, } from "@mui/material";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import axiosInstance from "../../axios/axiosInstance";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAlertDetails } from "../../redux/slices/AlertDetailsSlice";
import { useTheme } from "@mui/material/styles";
import { getStatusColor } from "./helper/helperFun";
import { formatDate } from "../../helper/formatDate";
import { useContext } from "react";
import { UserTransactionsSearch } from "../../context";
export const TransactionRow = ({ transaction, userId }) => {
  const { handleupdateTransactions } = useContext(UserTransactionsSearch);
  const theme = useTheme();
  const linkStyle = { textDecoration: "none", color: theme.palette.primary.main };
  const isOwner = userId === transaction?.ownerID?._id;
  const canCancel = transaction?.status === "Pending" && !isOwner;
  const canReview = !(isOwner || transaction?.productID?.isDeleted);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // set alert details to redux
  const setAlertDetailsToRedux = (type, message) => {
    dispatch(setAlertDetails({ type, message }));
  };
// handle cancel transaction
  const handleCancelTransaction = (id) => {
    axiosInstance.put(`/transactions/cancel/${id}`)
      .then(() => {
        setAlertDetailsToRedux("success", "Transaction canceled successfully");
        handleupdateTransactions(); // update transactions
      })
      .catch((err) => {
        if (err) {
          setAlertDetailsToRedux("error", err.response.data.error);
        }
      });
  };
// handle navigate to add review
  const handleNavigateToAddReview = (id) => {
    navigate(`/add-review/${id}`);
  };


  return (
    <TableRow>
      <TableCell align="center" sx={{ color: "primary.main" }}>
        <Link to={`/product-detail/${transaction?.productID?._id}`} style={linkStyle}>
          {transaction?.productID?.name}
        </Link>
      </TableCell>
      <TableCell align="center" sx={{ color: "secondary.main" }}>
        {formatDate(transaction?.startDate)}
      </TableCell>
      <TableCell align="center" sx={{ color: "secondary.main" }}>
        {formatDate(transaction?.endDate)}
      </TableCell>
      <TableCell align="center" sx={{ color: "secondary.main" }}>
        {transaction?.userID?._id === userId ? "You" : transaction?.userID?.username}
      </TableCell>
      <TableCell align="center">
        {isOwner ? "You" : transaction?.ownerID?.username}
      </TableCell>
      <TableCell align="center" sx={{ color: "secondary.main" }}>
        <Button
          variant="text"
          color="primary"
          sx={{ fontSize: ["10px", "15px"] }}
          disabled={!canCancel}
          onClick={() => handleCancelTransaction(transaction?._id)}
        >
          Cancel
        </Button>
      </TableCell>
      <TableCell align="center">
        <Button
          variant="text"
          color="primary"
          sx={{ fontSize: ["10px"] }}
          onClick={() => handleNavigateToAddReview(transaction?.productID?._id)}
          disabled={!canReview}
        >
          <AddIcon sx={{ fontSize: "20px" }} />
        </Button>
      </TableCell>
      <TableCell align="center" sx={{ color: getStatusColor(transaction?.status) }}>
        {transaction?.status}
      </TableCell>
    </TableRow>
  );
};