import { Box, Button, Container, InputAdornment, TextField, Typography } from '@mui/material'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../axios/axiosInstance';
import { useTranslation } from 'react-i18next';
import { useShowAlert } from '../hooks/useShowAlert';
export const SendReferralsCode = () => {
    const {showAlert} = useShowAlert()
    const { t } = useTranslation("sendRefferal")
    const [email, setEmail] = useState('')
    const [sended, setSended] = useState(false)
    const [loading, setLoading] = useState(false)

  
    // handle email change
    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }
    // handle form submit
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        axiosInstance.post('user/referral/send-code', { friendEmail: email }).then(() => {
            setSended(true)
            showAlert('success', 'Successfully sent referral code to your email!')
        }).catch((err) => {
            showAlert('error', err.response.data.error)
        }).finally(() => {
            setLoading(false)
        })
    }
    return (
        <Box my={8}>
            <Container maxWidth="sm">
                <Box sx={{ backgroundColor: "secondary.main", borderRadius: "20px", p: 8 }}>
                    {
                        sended ? (
                            <Box sx={{ py: 5 }}>
                                <Typography variant="h5" component="h2" sx={{ fontFamily: "Poppins-Bold", color: "primary.main", fontSize: ["25px"], textAlign: "center" }}>
                                    {t("success.title")}
                                </Typography>
                                <Typography variant="body1" component="p" sx={{ color: "white", fontFamily: "Poppins", fontWeight: "500", fontSize: "16px", textAlign: "center", marginTop: "20px" }}>
                                    {t("success.subtitle.1")}
                                    <br />
                                    {t("success.subtitle.2")}
                                </Typography>

                                <Button component={Link} to="/" fullWidth variant="contained" sx={{ backgroundColor: "primary.main", color: "white", fontFamily: "Poppins-Bold", fontSize: "16px", borderRadius: "10px", padding: "10px", mt: 4 }}>
                                    {t("success.button")}
                                </Button>

                            </Box>
                        ) : (
                            <Box>

                                <Typography variant='h4' component="h3" sx={{ color: "white", textAlign: "center", fontFamily: "Poppins-Bold" }}>
                                    {t("title.1")}
                                    <br />
                                    {t("title.2")}
                                </Typography>
                                <Typography variant='body2' sx={{ color: "white", textAlign: "center", mt: 2 }}>
                                    {t("subtitle")}
                                </Typography>

                                <TextField
                                    fullWidth
                                    placeholder={t("form.email")}
                                    id="outlined-start-adornment"
                                    sx={{
                                        backgroundColor: "white", mt: 3,
                                        // remove outline
                                        borderRadius: "10px",
                                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                            border: "none",
                                        },
                                    }}
                                    onChange={handleEmailChange}

                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">
                                            <MailOutlineIcon />
                                        </InputAdornment>,
                                    }}
                                />

                                <Button fullWidth variant="contained" sx={{ backgroundColor: "primary.main", color: "white", fontFamily: "Poppins-Bold", fontSize: "16px", borderRadius: "10px", padding: "10px", marginTop: "20px", }} onClick={handleSubmit} disabled={loading}>
                                    {loading ? t("form.button.loading") : t("form.button.text")}
                                </Button>

                            </Box>
                        )
                    }
                </Box>
            </Container>

        </Box>
    )
}