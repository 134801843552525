import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
export const CustomTextFiled = styled(TextField)({
    color: "secondary.main",
    '& .MuiOutlinedInput-root': { // To remove outline
        backgroundColor: "white",
        '& fieldset': {
            borderColor: 'transparent',

        },
        '&:hover fieldset': {
            borderColor: 'transparent',

        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent',
        },
    },
    '& .MuiFormLabel-root': {
        color: 'black', // To change label color
    },
});

