import { Button, Stack, TextField, Box, Typography } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { CircularProgress } from '@mui/material';

const CommentReviewForm = ({ initialValues, handleSubmit, validationSchema, buttonText, loading,type,placeholder }) => (
  <Formik
    initialValues={initialValues}
    onSubmit={handleSubmit}
    validationSchema={validationSchema}
    enableReinitialize={true}
  >
    {() =>{
      return (
     
      <Form>
        <Stack spacing={3}>
          <Box sx={{ backgroundColor: '#F4EFEA', p: 3, borderRadius: '10px' }}>
            <Typography variant="h6" sx={{ color: 'secondary.main', fontFamily: 'Poppins-Bold', pb: 2 }}>
              {buttonText.split(' ')[0]} {buttonText.split(' ')[1]} {/* Example: "Write Comment" */}
            </Typography>
            <Field
              name={type}
              as={TextField}
              fullWidth
              multiline
              rows={5}
              placeholder={placeholder}
              sx={{
                '& .MuiOutlinedInput-root': { // To remove outline
                  backgroundColor: 'white',
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                    borderRadius: '20px', // To remove round shape
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                  },
                },
              }}
            />
            <ErrorMessage name={type} component="div" style={{ color: 'red' }} />
          </Box>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ p: 1 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : buttonText}
          </Button>
        </Stack>
      </Form>
    )
    }}
  </Formik>
);

export default CommentReviewForm;
