import axiosInstance from "../../../axios/axiosInstance";
import { useShowAlert } from "../../../hooks/useShowAlert";
import useSendTokenToContact from "./SendToken";
export const useSignupSubmit = () => {
    const { showAlert } = useShowAlert();
    const { sendTokenToEmail, sendTokenToPhoneNumber } = useSendTokenToContact();
    const signupSubmit = ({ setLoading, signUpmethod, values }) => {
        setLoading(true);
        let finalValues = {};

        if (signUpmethod === "email") {
            finalValues = {
                email: values.identifier,
                password: values.password,
                username: values.username,
            };
        } else {
            finalValues = {
                phoneNumber: values.identifier,
                password: values.password,
                username: values.username,
            };
        }

        axiosInstance
            .post("/user/signup", finalValues)
            .then(() => {
                if (signUpmethod === "email") {
                    sendTokenToEmail(values.identifier);
                } else {
                    sendTokenToPhoneNumber(values.identifier);
                }
                setLoading(false);
            })
            .catch((err) => {
                if (err) {
                    showAlert("error", err.response.data.error);
                }
                setLoading(false);
            });
    };

    return { signupSubmit };
};
