import { Box, Button, CircularProgress, Container, Stack, TextField, Typography } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { contactWithUserValidationSchema } from "../validation/validationSchemas";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getUser } from "../redux/slices/AuthUser";
import axiosInstance from "../axios/axiosInstance";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useShowAlert } from "../hooks/useShowAlert";
export const ContactWithUser = () => {
  const { showAlert } = useShowAlert();
  const { t } = useTranslation("sendMessage");
  const navigate = useNavigate();
  const authUserId = useSelector(getUser)?.id; // get user id from redux
  const { userId, username } = useParams();
  const [loading, setLoading] = useState(false);
  const initialValues = {
    message: "",
  };

  // handle go back button
  const handleBack = () => {
    navigate(-1);
  };
  const handleSubmit = (values, { resetForm }) => {
    const contactValues = {
      sender: authUserId,
      receiver: userId,
      content: values.message,
    };
    if (!authUserId) {
      // if token not exists in localStorage then show alert by redux and after 3 seconds remove it
      showAlert("error", "Login first to send message");
      return;
    } else {
      setLoading(true);
      axiosInstance
        .post(
          "/messages",
          contactValues
        )
        .then(() => {
          showAlert("success", "Message sent successfully");

          setLoading(false);
          resetForm();
        })
        .catch((err) => {
          if (err) {
            showAlert("error", err.response.data.error);

          }
          setLoading(false);
        });
    }
  };

  return (
    <Box>

      <Box sx={{ pt: [3, 8], paddingLeft: [3, 8] }}>
        <ArrowBackIcon sx={{ color: "primary.main", fontSize: "40px", cursor: "pointer", fontWeight: "bold" }} onClick={handleBack} />
      </Box>
      <Container maxWidth="md" sx={{ my: 5, }}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={contactWithUserValidationSchema}
        >
          {() => (
            <Form>
              <Box sx={{ backgroundColor: "text.main", p: 3 }}>
                <Typography variant="h6" component="h3" sx={{ pb: 1, color: "secondary.main", fontWeight: "bold" }}>
                  {t("sendTo")}{" "}
                  <Typography
                    variant="h6"
                    component="span"
                    sx={{ color: "primary.main", display: "inline-block", px: 1, fontWeight: "bold" }}
                  >
                    {username}
                  </Typography>
                </Typography>
                <Stack spacing={3}>
                  <Field
                    name="message"
                    as={TextField}
                    multiline
                    rows={9}
                    placeholder={t("placeholder")}
                    sx={{
                      backgroundColor: "background.paper",
                      borderRadius: "10px",

                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      }
                    }}
                  />
                  <ErrorMessage
                    name="message"
                    component="div"
                    style={{ color: "red" }}
                  />
                </Stack>
              </Box>
              <Button
                type="submit"
                variant="contained"
                sx={{ width: "250px", mt: 2 }}
                disabled={loading}
              >
                {t("sendBtn")}{" "}
                {loading ? (
                  <CircularProgress
                    size={20}
                    sx={{ color: "shadow.main", marginLeft: "5px" }}
                  />
                ) : (
                  ""
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </Container>

    </Box>
  );
};
