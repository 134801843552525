import { Grid, TextField } from '@mui/material'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'
export const InputFiled = ({ errors, touched }) => {
  const { t } = useTranslation("UserDetailsDialog");
  const getErrorText = (error) => {
    return error ? t(`validation.${error}`) : '';
};

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Field
          as={TextField}
          fullWidth
          label={t("form.fName")}
          name="firstName"
          error={touched.firstName && Boolean(errors.firstName)}
          helperText={touched.firstName && getErrorText(errors.firstName)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          as={TextField}
          fullWidth
          label={t("form.lName")}
          name="lastName"
          error={touched.lastName && Boolean(errors.lastName)}
          helperText={touched.lastName && getErrorText(errors.lastName)}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          as={TextField}
          fullWidth
          label={t("form.dName")}
          name="displayName"
          error={
            touched.displayName && Boolean(errors.displayName)
          }
          helperText={touched.displayName && getErrorText(errors.displayName)}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          as={TextField}
          fullWidth
          label={t("form.phone")}
          name="phoneNumber"
          error={
            touched.phoneNumber && Boolean(errors.phoneNumber)
          }
          helperText={touched.phoneNumber && getErrorText(errors.phoneNumber)}
        />
      </Grid>

    </>
  )
}
