import { useState, useEffect } from "react";
import { Container, Grid, Typography, Button, Box } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUser } from "../redux/slices/AuthUser";
import { RentDate } from "../components/product-details/rent-date/RentDate";
import { Slider } from "../components/product-details/Slider";
import { ProductInfo } from "../components/product-details/ProductInfo";
import axiosInstance from "../axios/axiosInstance";
import { DisplayLoaction } from "../components/general/DisplayLoaction";
import { ShowItems } from "../components/product-details/comments-reviews/ShowItems";
import UserInformation from "../components/product-details/UserInformation";
import { useTranslation } from "react-i18next";
import { useShowAlert } from "../hooks/useShowAlert";
export const ProductDetails = () => {
  const { showAlert } = useShowAlert();
  const { t } = useTranslation("product-details");
  const authUser = useSelector(getUser);
  const navigate = useNavigate();
  const { productID } = useParams();
  const [product, setProduct] = useState({});
  const [notFound, setNotFound] = useState(false);
  
  const handleNavigateToReview = () => {
    if (product && authUser?.id === product.userID._id) {
      showAlert("error", "You can't add review to your own product");
      return;
    } else {
      navigate(`/add-review/${productID}`);
    }
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const res = await axiosInstance.get(`/products/${productID}`);
        setProduct(res.data.product);
      } catch (err) {
        if (err.response.status === 404) {
          setNotFound(true);
        }
      }

    };
    fetchProduct();
  }, [productID]);


  if (notFound) {
    return (
      <Container maxWidth={"lg"} sx={{ py: 5, height: "60vh", display: "flex", alignItems: "center", justifyContent: "center", }}>
        <Typography variant="h5" sx={{ textAlign: "center", color: "primary.main", fontFamily: "Poppins-Bold" }}>
          {t("404")}
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth={"lg"} sx={{ py: 5 }}>
      <Grid container spacing={8}>

        <Grid item xs={12} md={5}>
          <Slider images={product ? product.pictures : []} />
        </Grid>
        <Grid item xs={12} md={7}>
          <ProductInfo product={product} />
          <Grid container spacing={2} py={3}>
            <Grid item xs={12} md={6}>
              <DisplayLoaction location={product ? product.location : ""} />
            </Grid>
            <Grid item xs={12} md={6}>
              <ShowItems productID={productID} type="comments" />
              <Button
                variant="contained"
                sx={{ width: "content-fit", p: 1, my: 3 }}
                onClick={() => navigate(`/add-comment/${product ? product._id : ""}`)}
              >
                {t("addComment")}
              </Button>
            </Grid>
          </Grid>
          <RentDate product={product || {}} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <Box>
                <Typography variant="h5" sx={{ color: "secondary.main", fontWeight: "bold", mb: 2 }}>
                  {t("customer-reviews")}
                </Typography>
                <Button
                  variant="contained"
                  sx={{ width: "200px", p: 1 }}
                  onClick={handleNavigateToReview}
                >
                  {t("addReview")}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={7}>
              <ShowItems productID={productID} type="reviews" />
            </Grid>
          </Grid>
          <UserInformation user={product ? product.userID : ""} />
        </Grid>
      </Grid>
    </Container>
  );
};
