import { Box, Grid, Stack, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import location from "../../assets/images/location.webp";
import phone from "../../assets/images/phone.webp";
import email from "../../assets/images/email.webp";
import website from "../../assets/images/website.webp";

const info = [
    {
        icon: location,
        titleKey: "contactUs.location.title",
        infoKey: "contactUs.location.info",
        link: "https://www.google.com/maps/search/?api=1&query=76+Mill+Road,+Penfield,+NY+26"
    },
    {
        icon: phone,
        titleKey: "contactUs.phoneNumber.title",
        infoKey: "contactUs.phoneNumber.info",
        link: "tel:+1234567890"
    },
    {
        icon: email,
        titleKey: "contactUs.email.title",
        infoKey: "contactUs.email.info",
        link: "mailto:customerservice@directrent.com"
    },
    {
        icon: website,
        titleKey: "contactUs.website.title",
        infoKey: "contactUs.website.info",
        link: "http://www.directrent.com"
    }
];

export const ContactUsInfo = () => {
    const { t } = useTranslation("contactUs");

    return (
        <Box>
            <Grid container spacing={5} pt={3} pb="140px">
                {info.map((item) => (
                    <Grid item xs={12} sm={6} key={item.titleKey}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Link href={item.link} target="_blank">
                                <Box sx={{ backgroundColor: "primary.main", minWidth: "60px", minHeight: "60px", borderRadius: "10px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <img src={item.icon} alt="contact us" loading='lazy' style={{ maxWidth: "30px", maxHeight: "30px" }} />
                                </Box>
                            </Link>
                            <Stack spacing={1} sx={{ flexWrap: "wrap", flexGrow: 1 }}>
                                <Typography variant="h6" sx={{ color: "secondary.main", fontWeight: "bold" }}>
                                    {t(item.titleKey)}
                                </Typography>
                                <Link href={item.link} target="_blank" style={{ textDecoration: "none" }}>
                                    <Typography variant="body1" sx={{ color: "secondary.main", wordWrap: "break-word", overflowWrap: "break-word", wordBreak: "break-all" }}>
                                        {t(item.infoKey)}
                                    </Typography>
                                </Link>
                            </Stack>
                        </Stack>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};
