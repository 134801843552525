import { Avatar, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
const imageStyle = { width: "100%", height: "100%", borderRadius: "50%" }
const linkStyle = {
  textDecoration: "none",
  color: "red",
  fontSize: "14px",
}
const AvatarUI = ({ sender }) => {
  return (
    <Stack direction="row" sx={{ alignItems: "center" }}>
      <Avatar
        sx={{ textDecoration: "none", width: "30px", height: "30px", mr: 1 }}
      >
        {sender?.profilePicture ? (
          <img
            src={sender?.profilePicture || "user"}
            alt={sender?.username || "user"}
            style={imageStyle}
          />
        ) : (

          sender?.username?.[0].toUpperCase() || "user"
        )}
      </Avatar>
      <Link
        to={`/contactWithUser/${sender?._id}/${sender?.username}`}
        style={linkStyle}
      >
        {sender?.username || "user"}
      </Link>
    </Stack>
  )
}

export default AvatarUI