import { Box, Button, Container, Stack, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axiosInstance from '../axios/axiosInstance'
import { useTranslation } from 'react-i18next'
import { useShowAlert } from '../hooks/useShowAlert'
export const SendEmailResetPassword = () => {
    const { showAlert } = useShowAlert();
    const { t } = useTranslation("resetPassword")
    const navigate = useNavigate()
    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)

    // handle navigate to reset password page   
    const handleNavigateToResetPasswordPage = () => {
        navigate("/reset-password")
    }

    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    }

    const SendToken = () => {
        // if email is empty return error
        if (email === "") {
            showAlert("error", "Please Enter Your Email")

            return
        }
        setLoading(true)
        axiosInstance.post("/user/generate-reset-token", { email }
        ).then(() => {

            showAlert("success", "Please Check Your Email And Enter Code")

            handleNavigateToResetPasswordPage()
            setLoading(false)

        }).catch(err => {
            showAlert("error", err.response.data.error)

            setLoading(false)
        })
    }
    return (
        <Box sx={{ py: 8 }}>
            <Typography variant="h4" align="center" sx={{ mt: 2, color: "primary.main", fontWeight: "bold" }}>
                {t("title")}
            </Typography>
            <Stack sx={{ justifyContent: "center", alignItems: "center", height: "50vh" }}>
                <Container maxWidth="sm">
                    <TextField id="outlined-basic" label={t("enterEmail")} variant="outlined" sx={{
                        '& .MuiOutlinedInput-root': { // To remove outline
                            '& fieldset': {
                                borderColor: 'secondary.main',
                            },
                            '&:hover fieldset': {
                                borderColor: 'secondary.main',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'secondary.main',
                            },
                        },
                        '& .MuiFormLabel-root': {
                            color: 'secondary.main',
                        },
                    }} onChange={handleChangeEmail} fullWidth />
                    <Button variant="contained" color="primary" fullWidth sx={{ mt: 2 }} onClick={SendToken}>
                        {loading ? t("wait") : t("sendToken")}
                    </Button>
                </Container>
            </Stack>
        </Box>
    )
}