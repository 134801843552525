import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import InputFiled from "./InputFiled";
import UploadImages from "./uploading-image/UploadImages";
import { addProductValidationSchema } from "../../validation/validationSchemas";
import { useTranslation } from "react-i18next";
import useProductForm from "./hook/useProductForm"; 

const AddProductForm = () => {
  const { t } = useTranslation("add-product");
  const { handleImageChange, imageLoading, onSubmit, loading } = useProductForm();

  const savedProduct = JSON.parse(localStorage.getItem("productSaved"));
  const initialValues = savedProduct ? savedProduct : {
    category: "",
    name: "",
    price: "",
    per: "",
    description: "",
    condition: "",
    pictures: [],
  };

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={addProductValidationSchema}
      >
        {({ setFieldValue, errors, touched, values }) => (
          <Form>
            <Grid container spacing={5}>
              <Grid item xs={12} md={8}>
                <InputFiled
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  values={values}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <UploadImages
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  values={values}
                  handleImageChange={handleImageChange}
                  imageLoading={imageLoading}
                />
              </Grid>
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", my: 3 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                  size="large"
                  sx={{ width: ["100%", "30%"], fontFamily: "Poppins-Bold" }}
                >
                  {loading ?
                    <>
                      <CircularProgress size={24} sx={{ mr: "15px" }} />
                      {t("wait")}
                    </>
                    : t("submit")}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AddProductForm;
