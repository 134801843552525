import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import axiosInstance from '../../../axios/axiosInstance';
import { useParams } from 'react-router-dom';
import { uploadImage } from '../../../helper/UploadImage';
import { ImageBox } from './ImageBox';
import { NewImageBox } from './NewImageBox';
import { useShowAlert } from '../../../hooks/useShowAlert';
export const EditImages = () => {
    const { showAlert } = useShowAlert();
    const { id } = useParams();
    const [pictures, setPictures] = useState([]);
    const [loading, setLoading] = useState([]);
    const [newImageLoading, setNewImageLoading] = useState(false);
    const handleImageChange = async (event, index) => {
        const selectedImage = event.target.files[0];
        const isNewImage = index === pictures.length;
        setLoadingState(index, isNewImage, true);

        try {
            const imageUrl = await uploadImage(selectedImage);
            if (imageUrl) {
                const newPictures = [...pictures.slice(0, index), imageUrl, ...pictures.slice(index + 1)];
                setPictures(newPictures);
                await axiosInstance.put(`/products/${id}`, { pictures: newPictures });
                showAlert("success", "Image updated successfully");
            } else {
                showAlert("error", "Failed to get image URL");
            }
        } catch (error) {
            showAlert("error", error.message);
        } finally {
            setLoadingState(index, isNewImage, false);
        }
    };

    const setLoadingState = (index, isNewImage, state) => {
        if (isNewImage) {
            setNewImageLoading(state);
        } else {
            setLoading(prevState => {
                const newLoading = [...prevState];
                newLoading[index] = state;
                return newLoading;
            });
        }
    };


    const deleteImage = async (index) => {
        if (pictures.length === 1) {
            showAlert("error", "At least one image should be available");
            return;
        }

        try {
            const newPictures = [...pictures];
            newPictures.splice(index, 1);
            await axiosInstance.put(`/products/${id}`, { pictures: newPictures });
            setPictures(newPictures);
            showAlert("success", "Image deleted successfully");
        } catch (error) {
            showAlert("error", error.response.data.error);
        }
    };

    useEffect(() => {
        axiosInstance
            .get(`/products/${id}`)
            .then((res) => {
                setPictures(res.data.product.pictures);
                setLoading(new Array(res.data.product.pictures.length).fill(false));
            })
            .catch((err) => {
                showAlert("error", err.response.data.error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <Stack direction="row" sx={{ flexWrap: "wrap", gap: 2 }}>
            {pictures.map((picture, i) => (
                <ImageBox
                    key={i}
                    picture={picture}
                    i={i}
                    loading={loading[i]}
                    handleImageChange={handleImageChange}
                    deleteImage={deleteImage}
                />
            ))}
            <NewImageBox newImageLoading={newImageLoading} handleImageChange={handleImageChange} pictures={pictures} />
        </Stack>
    );
};
