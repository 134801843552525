// conditionally render states based on country dinamically
export const states = (country) => {
    switch (country) {
      case "United States":
        return ["California", "Florida", "New York", "Texas", "Washington"];
      case "Nigeria":
        return ["Abuja", "Lagos", "Kano", "Kaduna", "Enugu"];
      case "Ghana":
        return ["Accra", "Kumasi", "Tamale", "Takoradi", "Ho"];
      case "Kenya":
        return ["Nairobi", "Mombasa", "Kisumu", "Nakuru", "Eldoret"];
      case "South Africa":
        return ["Cape Town", "Johannesburg", "Durban", "Pretoria", "Bloemfontein"];
      default:
        return [""];
    }
  
  }