import { useState } from "react";
import { Formik } from "formik";
import { Typography, Box, Container, Paper, } from "@mui/material";
import { signupValidationSchema } from "../../../validation/validationSchemas";
import { SignUpFiled } from "./SignUpFiled";
import { MethodSignUp } from "./MethodSignUp";
import { LoginWithSocial } from "../LoginBySocialmedia/LoginWithSocial";
import { useSignupSubmit } from "../hooks/signupSubmit";
import { useTranslation } from "react-i18next";
import { HaveAcount } from "./HaveAcount";
const initialValues = {
  username: "",
  identifier: "",
  password: "",
};
const Signup = () => {
  const { t } = useTranslation("signup");
  const [loading, setLoading] = useState(false);
  const [signUpmethod, setSignUpMethod] = useState("email");
  const { signupSubmit } = useSignupSubmit();


  return (
    <Box sx={{ pt: 8 }}>
      <Typography variant="h2" align="center" sx={{ color: "primary.main", fontWeight: "bold", mb: 8, fontSize: ["35px", "60px"] }}>
        {t("title")}
      </Typography>
      <Box sx={{ backgroundColor: "text.main", py: "100px" }}>
        <Container maxWidth="sm">
          <Paper elevation={3} sx={{ p: 4, borderRadius: 2, backgroundColor: "secondary.main", px: 5 }}>
            <Typography variant="h4" align="center" sx={{ color: "primary.main", fontWeight: "bold", fontSize: ["30px", "40px"] }}>
              {t("title")}
            </Typography>
            <Typography variant="body2" align="center" sx={{ color: "white", my: 2 }}>
              {t("subTitle")}
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={signupValidationSchema}
              onSubmit={(values) => signupSubmit({ setLoading, signUpmethod, values })}
            >
              {({ values, handleChange, handleBlur, errors, touched }) => (
                <SignUpFiled values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched} loading={loading} signUpmethod={signUpmethod} />
              )}
            </Formik>

            <MethodSignUp signUpmethod={signUpmethod} setSignUpMethod={setSignUpMethod} />

            <LoginWithSocial />
            <HaveAcount />
          </Paper>
        </Container>
      </Box>
    </Box>

  );
};

export default Signup;